// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import DocView from "./DocView/DocView.partial.jsx";
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import "./Documents.partial.css"

export default class Documents extends _basePartial {
    constructor(props) {
        super(props)
    }
    renderDocuments() {
        return this.controller.documents?.map((doc, index) => {
            return <DocView key={"docview_"+index+" "+doc?.url} 
                            params={{parent: this.view, 
                                    controller: this.controller,
                                    document: doc,
                                    index: index}} />
            })
    }
    renderView() {
        return(
            <div className="" id={this.name}>
                <Row className="g-0 admin_doc_middle_row">
                    <Col>
                        <Row className="g-0 documents_row">
                            {/* <Col> */}
                                {this.renderDocuments()}
                           {/* </Col> */}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "company"}} />
                </Row>
            </div>
        )
    }
}
