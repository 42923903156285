// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _baseApiObject from "components/General/_baseApiObject";
import invoice_pdf from "./invoice.json"
import schema from "./schema.json"


export default class Invoice_pdf extends _baseApiObject {
    constructor(props) {
        super(props)
        // this.schema     = schema
        // this.test_data  = test_data
        this.requests   = {
            general: invoice_pdf
        }
    }
}
