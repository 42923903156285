// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import LoadboardPanel from "../../AdminPartials/LoadboardPanel.partial"
import ColumnController from "components/Column/Column.controller.jsx"
import image from "assets/graph.jpg"
import configs from "./Accounting.json"

export default class ColumnOneController extends ColumnController {
    constructor(props) {
        super(props)
        this.configs = configs.data
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = Array(3).fill(undefined)
        rows.map((row, index) => {
            let current = new Date();
            this.configs[index].image = image
            this.configs[index].default = this.configs[index].default.replace(/\{NAME\}/g, this.configs[index].name);
            rows[index] = {obj: LoadboardPanel, params: {params: {parent: this, 
                                controller: this.view.controller, 
                                editable: false, 
                                index: 0, 
                                data: this.configs?.[index]
                            }}}
        })
        return rows
    }
}