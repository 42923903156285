// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"
import graph_request from "assets/graphql/Carriers/Carriers.search.graphql.json"
import search_request from "assets/graphql/Carriers/Carriers.search.graphql.json"
import dropdown from "assets/graphql/Carriers/Carriers.selection.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Carriers extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            dropdown: dropdown,
            search: search_request
        }
    }
    async search({caller, value, callback, params}) {
        let results = value
        if (value.length >= 2) {
            let body = JSON.parse(JSON.stringify(graph_request))
            body.query = body.query.replace("carriers", 'carriers(name: "'+value+'%")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        if (callback !== undefined) {
            callback({caller: caller, params: params, results: results})
        }
        return results
    }
}
