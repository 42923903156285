import React from 'react'
import { MdOutlineRequestQuote, MdOutlinePersonOutline } from "react-icons/md";
// import { GoDashboard } from "react-icons/go";
import { BsTruckFlatbed } from "react-icons/bs";
import { FaBoxes } from "react-icons/fa";

import Home from "module/pages/Home/Home.view.jsx"


const mContext = {
                    menus: [[{name: "Home",  internal: true, link: "/"},
                    {name: "Load",  internal: true, link: "/load"}
                        ]],
                    pages: {
                        "/": {link: "/", page: <Home />},
                    "/load": {link: "/load", page: <Home />},
                        },
                    default: "/"
}
mContext.getMenu = function() {
    return mContext.menus
}

mContext.getPages = function() {
    return mContext.pages
}
mContext.getPage = function({link, params}) {
    return mContext.pages[link].page
}
const ModuleContext = React.createContext(mContext)

export const ModuleProvider = ModuleContext.Provider
export const ModuleConsumer = ModuleContext.Consumer

export default ModuleContext
