// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class HelpPartialController extends _viewController {
    constructor(props) {
        super(props)
    }
    get context() {
        return this?.controller?.context
    }
}