// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import "./Home.css"
import Controller from "./Home.controller.jsx"
import CompanyCard from "./Partials/CompanyCard/CompanyCard.partial";


// ALL OTHER MODULES

import _basePanelView from "components/General/_basePanelView.jsx"
import { MainConsumer } from "controllers/MainContext"

import MainContext from "controllers/MainContext.jsx";
import { Row, Col, Card } from "react-bootstrap";
import MultiPanel from "components/MultiPanels/MultiPanel";
import LoadDetails from "./Partials/Load_Details/Load_Details.partial.jsx";


class Home extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({ params: { view: this } })
        this.custom_canvas = true
        this.state.states = []
        this.state.trailer_types = []
        this.state.special_requirements = []
        this.state.statuses = []
        this.state.data = {
            line_item: {},
            line_items: [{}],
            commodities: [],
            notes: [],
            stops: []
        }
        this.state.component = []
        this.state.stops = {
            pickup: [],
            dropoff: []
        }
    }
    renderView() {
        console.log(this)
        return (
            <Col xs={12} class="generic_100 g-0 container"  >
                <Row className="pad_rows">
                    <Col>
                        <img className="profile-photo" src={require("../../assets/cotalogo.png")} alt={"Cota Systems"} />
                    </Col>
                    <Col className="load_number">
			{this.controller?.load?.number}
                    </Col>
                </Row>
                <Row className="pad_rows">
                    <Col>
                        <CompanyCard params={{ parent: this, controller: this.controller, role: "pickup" }} />
                    </Col>
                </Row>
                {
                    this.controller.load.stops.map((stop, index) => {
                        return (
                            <Row className="pad_rows">
                                <Col>
                                    <CompanyCard params={{ parent: this, controller: this.controller, role: "stops", obj: stop, index: index }} />
                                </Col>
                            </Row>
                        )
                    })
                }
                <Row className="pad_rows">
                    <Col>
                        <CompanyCard params={{ parent: this, controller: this.controller, role: "consignee" }} />
                    </Col>
                </Row>
                <Row className="pad_rows">
                    <Col className="g-0">
                        <LoadDetails params={{ parent: this, controller: this.controller }} />
                    </Col>
                </Row>
            </Col>
        )
    }
}
Home.contextType = MainContext
export default Home;
