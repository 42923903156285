// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import creator_request from "assets/graphql/Quotes/Quote.creator.graphql.json"
import customer_request from "assets/graphql/Quotes/Quote.customer.graphql.json"


export default class Quotes extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            creators: creator_request,
            customers: customer_request
        }
    }
}
