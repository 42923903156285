//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./CompanyCard.partial.controller.jsx";
import Modal from "../../../EditShipperModal/EditShipper.modal.jsx";
import RoleObject from "components/NewLoadPartials/ShipperObject/Shipper.partial.jsx"

import "./CompanyCard.css";

export default class CompanyCard extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this } })
        this.state.type = this.partial_controller.type
    }
    get role_css() {
        return this.state?.role
    }
    get role() {
        return this.state?.role
    }
    render_delete() {
        if(this.state?.role !== "stops") { return <></> }
        return (
            <Row style={{marginTop: "-20px"}} className="g-0 pb-1">
                <Col className="d-flex justify-content-end pe-1">
                    <Button className="delete_btn" onClick={this.partial_controller.delete_stop.bind(this)}>
                        Delete stop
                    </Button>
                </Col>
            </Row>
        )
    }
    renderView() {
	console.log("----========================== NEW STOP ---")
	console.log(this)
	console.log(this.controller)
	console.log((this.state?.obj?.stop_type === undefined) ? this.state.type : this.state?.obj?.stop_type)
	console.log(this.state?.obj)
        return (
            <Row id={this.name} className="cota_panel_border">
                <Col className="g-0 add_shadow">
                    <Row className="g-0">
                        <Col className="g-0">
                            <Row className="g-0">
                                <Col className="g-0">
                                    &nbsp;
                                </Col>
                                <Col sm={2} className={"g-0 uc_text tag " +this.role_css}>
                                    {(this.state?.obj !== undefined) ? this.state?.obj?.stop_type : this.role}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <RoleObject params={{parent: this, controller: this.controller, obj: this.state?.obj,
                                            type: this.role, editable: true,
                                            borderless: true, title: false, padding: true,
                                            modal: true, index: this.state?.index,
                                            no_edit: true, no_close: true, no_rep:true }}/>
                    {this.render_delete()}
                </Col>
            </Row>
        )
    }
}
