// GENERAL REACT MODULES
import { Button } from 'react-bootstrap';

// ALL OTHER MODULES
import SettingsPage from "pages/Settings/SettingsPage.view.jsx"
import AdminPage from "pages/Admin/Admin.view.jsx"
import ManageUsersPage from 'pages/ManageUsers/ManageUsers.view'
import HelpPage from "pages/Help/Help.view.jsx"
import FeedbackPage from 'pages/Feedback/Feedback.view.jsx';
import _viewController from "controllers/ViewController/_viewController.jsx";
// import ChatMenu from "components/Chat/ChatMenu.view"

export default class DashboardViewController extends _viewController {
    constructor(props) {
        super(props)
    }
    renderMainWindow() {
        // console.log("--- renderMainWindow ---")
        if (this.view?.state?.pages === undefined) {
            return this.defaultWindow()
        }
//      let pathname = window.location.pathname
        let path = window.location.pathname.split('/')
        let pathname = "/"+path[1]
        this.context.path = path
        if (!this.view?.state?.authClient?.idTokenParsed?.roles?.includes("INTERNAL_COTA_USER")  && pathname === "/") {
            pathname = "/"
        }
        // console.log(this)
        // if(this?.view?.state?.self?.roles?.length <= 0) {
        //     let def = this?.sidebar?.context?.default
        //     if(def !== undefined) {
        //         pathname = def
        //     }
        // }
        switch(pathname) {
            case "/settings":
                return (
                    <SettingsPage params={{parent: this}}/>
                )
            case "/admin":
                return (
                    <AdminPage params={{parent: this}}/>
                )
            case "/manage-users":
                return (
                    <ManageUsersPage params={{parent: this}}/>
                )
            case "/help":
                return (
                    <HelpPage params={{parent: this}} />
                )
            case "/feedback": 
                return (
                    <FeedbackPage params={{parent: this}}/>
                )
            case this.view?.state?.pages[pathname]?.link:
                if (this.view?.state?.pages[pathname]?.page !== undefined) {
                    return (
                        this.view?.state?.pages[pathname].page
                    )
                }
                break
            default:
                return this.defaultWindow()
        }
    }
    updateView() {
        this.view?.forceUpdate()
    }
    defaultWindow() {
        return(
            <div>
                Logged In
                <Button variant="primary" className="RoundedButton">Logout</Button>
            </div>
        )
    }
    getChatMenu({chatToggle}) {
        if (chatToggle) {
            return (
                <></>
                // <ChatMenu params={{parent: this}}/>
            )
        }
    }
    check_auth() {
        if (process.env.REACT_APP_NOLOGIN) {
            return true
        } else {
            let authClient = this.getState({key: "authClient"})
            if (authClient) {
                if (authClient.authenticated) {
                    return true
                }
            }
        }
        return false
    }
    process_user_load({caller, params, results}) {
        caller.view.setState({loading: false,
                        self: results?.data?.self})
    }
}
