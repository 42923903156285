// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "controllers/ModelControllers/_ModelController";

export default class LineItemModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.number_of_keys = 7
    }
    get rate_type() {
        return this.data?.rate_type
    }
    set rate_type(value) {
        this.data.rate_type = value
    }
    get number() {
        return Number(this.data?.number)
    }
    set number(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.number = Number(value)
            }
        }
    }

    get length() {
        return Number(this.data?.length)
    }
    set length(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.length = Number(value)
            }
        }
    }

    get width() {
        return Number(this.data?.width)
    }
    set width(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.width = Number(value)
            }
        }
    }
    get height() {
        return Number(this.data?.height)
    }
    set height(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.height = Number(value)
            }
        }
    }
    get weight() {
        return Number(this.data?.weight) 
    }
    set weight(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.weight = Number(value)
            }
        }
    }

    get class() {
        return this.data?.class
    }
    set class(value) {
        this.data.class = value
    }
    get price() {
        return this.data.price
    }
    set price(value) {
        this.data.price = value
    }
    get rate() {
        let total = this.number * this.price
        if(isNaN(total)) {
            return 0
        }
        return total
    }
    get is_finished() {
        if (Object.keys(this.all)?.length === this.number_of_keys) {
            return true
        }
        return false
    }
    update_follow_on() {
        if (this.is_finished) {
            this.controller.add_item()
        }
    }
    get data() {
        return this.controller?.getState({key: "data"})
    }
    get summary() {
        let _summary = {}
        _summary.rate_type = this.rate_type
        _summary.number = Number(this.number)
        _summary.price = Number(this.price)
        _summary.rate = this.rate
        return _summary
    }
}
