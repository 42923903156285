
// Module specific imports:
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class CotaUserBarModalController extends _viewController {
    constructor(props) {
        super(props)
    }

    get sidebar() {
        return this.controller?.view?.state?.context?.sidebar
    }
    handle_about_cota() {
        const url = "https://www.cotasystems.com/"
        window.open(url, '_blank');
    }
    handle_user_details() {
        this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[7]?.[0], this.controller.view.state.context)
    }
    handle_privacy_policy() {
        const url = "https://www.cotasystems.com/privacy"
        window.open(url, '_blank');
    }
    handle_terms_of_use() {
        const url = "https://www.cotasystems.com/terms"
        window.open(url, '_blank');
    }
    handle_sign_out() {
        this.controller?.controller?.context?.authClient?.logout()
    }
}