// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'
import { FaAngleLeft } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./CotaUserBar.controller.jsx"
import "./CotaUserBar.css"
import CotaUserMenu from "./CotaUserMenu.partial.jsx";

export default class CotaUserBar extends _basePartial {
    constructor (props) {
        super(props)
        this.partial_controller  = new Controller({params: {view: this, controller: this?.controller}})
    }
    followDidMount() {
        this.partial_controller.load_data()
    }
    get title() {
        return this.state?.title?.charAt(0)?.toUpperCase() + this.state?.title?.slice(1)
    }
    add_back_button() {
        let title = "Back to "+this.title
        if (this.state.parent?.state?.panel_params?.close_func === "backPanel") {
            title = this.state.parent?.state?.panel_params?.close_title
        }
        return (
            <span className="title_text" type="button" onClick={() => this.partial_controller.handle_nav()}>
                <FaAngleLeft className="m-0"/>{title}
            </span>
        )
    }
    add_title() {
        if(this.state.return) {
            return this.add_back_button()
        }
        return <span className="title_text">{this.title}</span>
    }
    renderView() {
        return (
            <Row id={this.name} className="pad_row w-100 g-0 title_bar">
                <Col>
                    {this.add_title()}
                </Col>
                <Col xs="auto">
                    <CotaUserMenu params={{parent: this, controller: this.partial_controller}}/>
                </Col>
            </Row>
        )
    }
}