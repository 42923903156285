// GENERAL REACT MODULES
import { Row, Col, Button, Form } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal";
import "./UploadModal.css";
import config_data from "./modal.json"
import { SelectDropdown } from "components/Dropdowns/Dropdowns.jsx"
import test_data from "./test_data.json";

export default class UploadModal extends _baseModal {
    constructor(props) {
        super(props)
	    this.state.form         = config_data.form_objects
        this.modal_controller = this.state.parent?.partial_controller
        this.controller.setState({key: "private_file", value: true})
	    // this.state.docTypes = test_data.docTypes
	    this.state.docTypes = []
        this.state.modal_params = {
            positioned: "middle_of_panel", 
            ignore_sidebar: true, 
            title: "Upload File",                         
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                  
            close_on_outside_click: false,                    
            draggable: false,                              
        }
    }
    renderView() {
	    this.state.docTypes = this.modal_controller.getState({key: "document_types"})
        return (
            <>
                <Row className="g-0 col_padding remove_top_padding">
                    <Col className="g-0 col_padding remove_padding">
                        <Row className="g-0">
                            <Col>
                                <Form.Control type="text" className="disabled"
                                    // value={this.controller.view.state?.current_file?.name} 
                                    placeholder={this.controller.view.state?.current_file?.name ?? "File name"} disabled/>
                                {/* {this.controller?.view?.formObject({data: this.state.form.note, owner: this, index: 0, controller: this.modal_controller})} */}
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col sm={2} className="checkbox_title">
                                <Form.Check type="checkbox" label="Private" 
                                    defaultChecked={true} 
                                    onChange={this.modal_controller.handle_checkbox.bind(this)}
                                    />
                                {/* {this.controller?.view?.formObject({data: this.state.form.viewable, owner: this, index: 0, controller: this.modal_controller})} */}
                            </Col>
                            <Col />
                        </Row>
                        <Row className="pt-2" style={{minWidth: "400px"}}>
                            <Col sm={4}>
                                Document Type:
                            </Col>
                            <Col>
                                <div className="absolute">
                                    <div className="" style={{minWidth: "226px"}}>
                                        {this.formObject({data: this.state.form.doc_types, owner: this, options: this.state.docTypes})}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="pe-3">
                            <Col />
                            <Col>
                                <Button size="sm" variant="secondary" className='btn_secondary'
                                    onClick={() => this.modal_controller.handle_close()}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={"edit_contact button_col "}>
                                <Button size="sm" onClick={() => this.modal_controller.upload()} className={"primary_button_coloring "+this._theme}>
                                    Submit
                                </Button>
                            </Col>
                            {/* <Col sm={1}/> */}
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
