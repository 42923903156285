// GENERAL REACT MODULES
import { Component } from "react";

export default class _baseComponent extends Component {
    constructor(props) {
        super()
        this._isMounted = false
        this.controller = undefined
        this.name       = this.constructor.name
        this.activity_level = 0
        this.state = {
            errors: undefined
        }
        if (props !== undefined) {
            if (props?.params !== undefined) {
                Object.keys(props?.params).map((param, index) => {
                    this.state[param] = props?.params[param]
                    return undefined
                })
            }
            if (this.state.parent !== undefined) {
                this.api = this.state.parent?.api
                // this.api = (this.state.parent?.api === undefined) ? new ApiController : this.state.parent.api
            }
            if (props?.params?.controller !== undefined) {
                this.controller = props.params?.controller
            }
            if (props?.params?.parent_override !== undefined) {
                this.parent_override = props.params?.parent_override
            }
            this.componentLoadProps()
        }
    }
    get parent() {
        return this.state.parent
    }
    get cntr() {
        if (this.controller !== undefined) {
            return this.controller
        } else {
            return this.parent?.controller
        }
    }
    get _theme() {
        return this.cntr?._theme
    }
    get_settings() {
        if (this?.settings !== undefined) {
            return this?.settings
        }
        return undefined
    }
    get_data() {
        if (this.get_settings()?.data !== undefined) {
            return this.get_settings()?.data 
        }
    }
    data(data_name) {
        if (this.get_settings()?.data !== undefined) {
            return this.get_settings()?.data[data_name]
        }
        return ""
    }
    get_objects() {
        if (this.get_settings()?.objects !== undefined) {
            return this.get_settings()?.objects 
        }
    }
    objects(object_name) {
        if (this.get_settings()?.objects !== undefined) {
            return this.get_settings()?.objects[object_name]
        }
        return ""
    }
    tab(tab_name) {
        if (this.get_settings()?.tabs !== undefined) {
            return this.get_settings()?.tabs[tab_name]
        }
        return ""
    }
    addProps(props) {
        Object.keys(props).map((prop, index) => {
            this.state[prop] = props[prop]
            return undefined
        })
    }
    componentDidMount() {
        this._isMounted = true
        this.followDidMount()
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.followUnDidMount()
    }
    componentLoadProps() {

    }
    followDidMount() {
        
    }
    followUnDidMount() {

    }
    check_role(role) {
        return this.controller.check_role(role)
    }
    get height() {
        return this?.divElement?.clientHeight
    }
    get width() {
        return this?.divElement?.clientWidth
    }
}
String.prototype.deep_copy = function() {
    try {
        return JSON.parse(JSON.stringify(this))
    } catch (e) {
        return this
    }
}
Object.prototype.copy = function() {
    try {
        return JSON.parse(JSON.stringify(this))
    } catch (e) {
        return this
    }
}
