// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Invite extends _baseApiObject {
    constructor(props) {
        super(props)
        this.requests   = { }
    }
}
