// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController";

// REQUESTS
import self_request from "assets/graphql/Users/self.graphql.json"

export default class ManageUsersController extends _viewController {
    constructor(props) {
        super(props)
        this.requests = [{request: self_request, callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    get employees() {
        return this.view.state.employees
    }
    load_user({caller, params, results}) {
        if(results?.errors !== undefined) { 
            console.log("Error processing self request", results.errors)
        }
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            let length = results.data.self.companies.length - 1
            caller.view.setState({
                company_id: results.data.self.companies[length].id
            })
        }
    }
}