// GENERAL REACT MODULES
import stringifyObject from 'stringify-object';

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"
import graph_request from "assets/graphql/Companies/Companies.search.graphql.json"
import graph_id from "assets/graphql/Companies/Company.graphql.json"
import new_company from "assets/graphql/Companies/Company.create.graphql.json"
import join_company from "assets/graphql/Companies/Company.join.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Companies extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
    }
    async get({caller, value, callback, params, id}) {
	console.log(id)
	console.log(caller)
        if (id !== undefined) {
            let body = JSON.parse(JSON.stringify(graph_id))
            body.query = body.query.replace("company", 'company(id: "'+id+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            return await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        return undefined
    }
    async create({caller, value, callback, params, id}) {
        let body = JSON.parse(JSON.stringify(new_company))
        body.query = body.query.replace("createCompany", 'createCompany(input: '+value+')')
        params = (params === undefined) ? {} : params
        params.body = body
        return await this.ask({caller: caller, callback: callback, params: params, nocache: true})
    }
    async join({caller, value, callback, params, id}) {
            let body = JSON.parse(JSON.stringify(join_company))
            body.query = body.query.replace("joinCompany", 'joinCompany(input: '+value+')')
            params = (params === undefined) ? {} : params
            params.body = body
	console.log(body)
            return await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        return undefined
    }
    async search({caller, value, callback, params}) {
        let results = value
        if (value.length >= 2) {
            let body = JSON.parse(JSON.stringify(graph_request))
            body.query = body.query.replace("companies", 'companies(name: "'+value+'%")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        if (callback !== undefined) {
            callback({caller: caller, params: params, results: results})
        }
        return results
    }
}
