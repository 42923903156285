// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button} from "react-bootstrap";
// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./EditShipper.modal.controller.jsx";
import form_configs from "./EditShipper.json"
import "./EditShipper.modal.css"

export default class EditShipperModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.dims = {width: 636, height: 980}
        this.state.form = form_configs.form_objects?.[this.type]
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "Edit Shipper and Pickup Details",                
            title_size: "",                     
            closeable: true,
            draggable: true,                  
            has_overlay: false,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
          }
    }
    get type() {
        return this.state.parent.state.type
    }
    renderView() {
        this.state.data[this.type] = this.modal_controller.role_object
        return (
            <>
                <Row>
                    <Col className="titles_font">
                        PICKUP #
                    </Col>
                </Row>
                <Row className="width">
                    <Col>
                        {this.formObject({ data: this.state.form?.pickup_number, controller: this.modal_controller})}
                    </Col>
                </Row>
                <hr className="hr"/>
                <Row className="nowrap_pad_">
                    <Col>
                        <Row>
                            <Col className="titles_font">
                                PICKUP DATE
                            </Col>
                        </Row>
                        <Row className="r_pad input_row left_width">
                            <Col >
                                {this.formObject({ data: this.state.form?.delivery_date, controller: this.modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                    <Col >
                        <Row className="half_rem flex-nowrap">
                            <Col className="titles_font">
                                PICKUP
                            </Col>
                        </Row>
                        <Row className="r_pad input_row quarter_marg flex-nowrap">
                            <Col className="temp">
                                {this.formObject({ data: this.state.form?.delivery_time, controller: this.modal_controller})} 
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="checkbox">
                    <Col className="half_rem">
                        <Row>
                            {this.formObject({ data: this.state.form?.appointment_required, controller: this.modal_controller})}
                        </Row> 
                    </Col>
                </Row>
            <hr/>
            <Row>
                <Col>
                </Col>
                <Col className="button_min_w g-0">
                    <Button className={"center_button clear_button "+this._theme} onClick={() => this.modal_controller.cancel()} >
                        Cancel
                    </Button>
                </Col>
                <Col className="button_min_w g-0">
                    <Button className={"center_button primary_button_coloring "+this._theme} onClick={() => this.modal_controller.save_action()} >
                        Save
                    </Button>
                </Col>
            </Row>
        </>
        );
    }
}

