// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController";

export default class FeedbackController extends _viewController {
    constructor(props) {
        super(props)
    }
    get project_url() {
        if(window.location.href.includes("freightpro")) {
            return "https://cota-systems.feedbear.com/boards/freightpro?embed=true"
        }
        return "https://cota-systems.feedbear.com/boards/carrierpro?embed=true"
    }
}