// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./Admin.controller.jsx";
import "./Admin.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import { partnersOptions, integrationsOptions } from "./Options.js"
import configs from "./Admin.json"

export default class AdminPage extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller                     = new Controller({params: {view: this}})
        this.state.form                     = configs.form_objects
        this.state.edit_mode                = false
        this.state.files                    = []
        this.state.documents                = []
        this.state.panel_name               = "admin"
        this.state.selection                = "contact_info"
        this.state.partnersSelection        = undefined
        this.state.integrationsSelection    = undefined
        this.state.states                   = []
        this.state.countries                = []
    }
    get company_name() {
        if (this.controller?.company?.name !== undefined) {
            return this.controller?.company?.name
        }
        return "Cota Systems Inc."
    }
    renderMain() {
        // console.log(this)
        return(
            <Container className="generic_100 g-0" id={this.name}>
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Admin"}}/>
                        {/* NEED to make a partial for the admin bar, will most likely help out with multi companies,
                                OR MAKE THIS ADMIN VIEW A _basePanelView */}
                        <Row className={"g-0 admin_bar "+this._theme}>
                            {/* <Col className="admin_item admin_item_title" title="main">
                                {this.company_name}
                            </Col> */}
                            <Col className={"admin_item admin_button"} title="contact_info" onClick={e => this.controller.change_page(e)}>
                                Company
                            </Col>
                            <Col className={"admin_item admin_button"} title="documents" onClick={e => this.controller.change_page(e)}>
                                Documents
                            </Col>
                            <Col className={"admin_item admin_button" + "" + this.controller?.display_internal()} title="integrations">
                                {this.formObject({data: this.state.form.integrations, owner: this, options: integrationsOptions})}
                            </Col>
                            <Col className={"admin_item admin_button" + "" + this.controller?.display_internal()} title="partners">
                                {this.formObject({data: this.state.form.partners, owner: this, options: partnersOptions})}
                            </Col>  
                        </Row>
                        <Row className="generic_100 g-0 mt-2">
                            <Col>
                                {this.controller?.load_page(this.state.selection)}                            
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
