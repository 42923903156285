// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

// MODULE SPECIFIC IMPORTS 
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./Feedback.controller.jsx"
import "./Feedback.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import FeedBearWidget from "components/Integrations/FeedBearWidget/FeedBearWidget.view.jsx";

export default class Feedback extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderMain() {
        return (
            <iframe 
                id="feedbear_iframe"
                src={this.controller.project_url}
                width="100%" 
                // height="300" 
                height="100%"
            />
            // <Container id={this.name} className="generic_100 g-0">
            //     <Row className="max_height">
            //         <Col className="flex_wrapper_rows">
            //             {/* <CotaUserBar params={{parent: this, controller: this.controller, title: "Request Features"}}/> */}
            //             <Row className="max_height">
            //                 <Col>
            //                     <iframe 
            //                         id="feedbear_iframe"
            //                         src="https://cota-systems.feedbear.com/boards/carrierpro?embed=true" 
            //                         width="100%" 
            //                         // height="300" 
            //                         height="100%"
            //                     />
            //                 </Col>
            //             </Row>
            //             {/* <FeedBearWidget params={{parent: this, controller: this.controller}}/> */}
            //         </Col>
            //     </Row>
            // </Container>
        )
    }
}