// GENERAL REACT MODULES

// ALL OTHER MODULES
import _viewController from "controllers/ViewController/_viewController";

export default class GrafanaFrameController extends _viewController {
    constructor(props) {
        super(props)
    }
    load() {
        return this.view?.state?.context?.cache?.get_from_cache({key: this.view?.state?.panel_name, id: this.view?.state?.url})
    }
    save(object) {
        this.view?.state?.context?.cache?.put_in_cache({key: this.view?.state?.panel_name, id: this.view?.state?.url, value: object})
    }
    refresh() {
        this.forceUpdate()
    }
    get_url() {
        let product = window?.location?.href?.split('//')?.[1]?.split('.')?.[0]
        let url		= this.getState({key: "url"})
        let filters	= this.getState({key: "filters"})
        if (filters?.time !== undefined) {
            filters.from    = (filters.time.from !== undefined) ? filters.time.from : this.view.state?.panel_configs[this.view.state?.panel_name]?.from
            filters.to      = (filters.time.to !== undefined) ? filters.time.to : this.view.state?.panel_configs[this.view.state?.panel_name]?.to
            delete filters.time
        }
        if (filters !== undefined && filters !== null) {
            Object.keys(filters).map((filter, index) => {
                if (filters[filter] !== undefined) {
                    let prefix = ""
                    if (filter !== "to" && filter !== "from") {
                        prefix = "var-"
                    }
                    url = url+"&"+prefix+filter+"="+filters[filter]
                }
            })
        }
        if (product !== undefined) {
            url = url+"&var-product="+product
        }
        if (this.view?.state?.context?.authClient?.idToken !== undefined) {
            url = url+"&var-jwt="+this.view?.state?.context?.authClient?.idToken
        }
        if (this.view?.parent?.state?.page !== undefined) {
            url = url+"&var-page="+this.view?.parent?.state?.page 
        }
        if (this.view?.parent?.state?.query_params !== undefined) {
            url = url+"&var-params="+this.view?.parent?.state?.query_params 
        }
        if (this.view?.parent?.state?.configs?.refresh !== -1 && this.view?.parent?.state?.configs?.refresh !== undefined) {
            url = url+"&refresh="+this.view?.parent?.state?.configs?.refresh+"s"
        }
        if (this.view?.state?.configs?.rows !== undefined) {
            url = url+"&var-rows="+this.view?.state?.configs?.rows
        }
        return url
    }
    processEventReaction(decoded) {
       switch (decoded.channel) {
            case "grafana_nav":
                this.view.setState({loading: true, skip_next: true})
                let page = (this.view?.state?.page === undefined) ? 0 : this.view.state.page
                let new_page = undefined
                if (decoded?.message?.data?.direction === true) {
                    new_page = page+1
                } else {
                    new_page = page-1
                    new_page = (new_page >= 0) ? new_page : 0
                }
                this.view.setState({page: new_page})
                let url = this.get_url()
                url = url+"&var-page="+new_page

                this.view.grafana_ref?.contentWindow?.location.replace(url)
                break
            case "grafana_loader":
                if ((!this.view.state.skip_next || this.view?.state?.page === 0) && this.view._isMounted) {
                    if (parseInt(decoded.message.panel_id) === this.view?.state?.panel_configs[this.view?.state?.panel_name]?.panelid) {
                        if (this.view.state.loading) {
                            this.view.setState({loading: false})
                        }
                    }
                }
                this.view.state.skip_next = false
                break
            case "grafana_search":
                let json = decoded.message?.data
                let filters = this.view.state.filters
                filters[json?.name] = json?.value
                this.view.state.filters = filters
                this.view.grafana_ref?.contentWindow?.location.replace(this.get_url())
                break
    
            default:
                break
        }
        this.view?.controller?.follow_event(decoded)
    }

}
