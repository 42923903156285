// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject"
import asset_request from "assets/graphql/Assets/Assets.graphql.json"
import graph_request from "./Assets.search.graphql.json"

export default class Assets extends _baseApiObject {
    constructor(props) {
        super(props)
        this.scheme     = schema
        this.test_data  = test_data
        this.requests   = {
            general: asset_request
        }
    }
    async search({caller, value, values, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(graph_request))
            if(params?.data?.filter !== undefined) {
                body.query = body.query.replace("assets", 'assets(asset_type: "'+params.data.filter+'", asset_number: "'+value+'%"'+this.append_values(values)+')')
            } else {
                body.query = body.query.replace("assets", 'assets(asset_number: "'+value+'%"'+this.append_values(values)+')')
            }
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        if (callback !== undefined) {
            callback({caller: caller, params: params, results: results})
        }
        return results
    }
    append_values(values) {
        let append_string = " "
        if (values !== undefined) {
            Object.keys(values).map((k, i) => {
                append_string = append_string+', '+k+':'+((!isNaN(values[k])) ? values[k]: '"'+values[k]+'"')
            })
        }
        return append_string
    }
}
