// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'
import { FaImage } from "react-icons/fa"

//MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./CompanyInfo.partial.controller.jsx"
import "./CompanyInfo.css"
import config_data from "./CompanyInfo.json"
import { countriesOptions } from "assets/countryCodes.js"

export default class CompanyInfoPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
    }
    get states() {
        return this.controller.states
    }
    // Have not done this before so may be unconvential but would like to try it out.
    get form() {
        return config_data.form_objects
    }
    followDidMount() {
        this.forceUpdate()
    }
    render_company_search() {
        if(this.partial_controller.internal_user) {
            return this.formObject({data: this.form.company, controller: this.partial_controller, owner: this})
        }
        return <></>
    }
    render_labeled_input({label, data, options}) {
        return (
            <Row className="mb_label_input">
                <Col>
                    <Row className="label_row">
                        <Col className="label">
                            {label}
                        </Col>
                    </Row>
                    <Row>
                        {/* Adding this key allows for rerender of the inputs, removing sticky text */}
                        <Col key={this.state.key_num+label+this.partial_controller.company._id}>
                            {this.formObject({data: data, controller: this.partial_controller, owner: this, options: options, disabled: !this.partial_controller.editable})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_edit_buttons() {
        if (!this.partial_controller.editable) return <></>
        return (<>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
            <Row className="padding_row g-0 mb-3">
                <Col>
                    <Button onClick={() => this.partial_controller.reset()} className="clear_button">
                        Discard Changes
                    </Button>
                </Col>
                <Col sm={1}>
                    <Button onClick={() => this.partial_controller.save()} className={"primary_button_coloring "+this._theme}>
                        Save
                    </Button>
                </Col>
            </Row>
        </>)
    }
    renderView() {
        // console.log(this)
        this.partial_controller.get_company_data()
        return (
            <div id={this.name}>
                <Row className="generic_100 panel_object g-0 add_shadow">
                    <Col>
                        <Row className="mt-4 mb-4 padding_row">
                            <Col className="title">
                                Company Information
                            </Col>
                            <Col>
                                {this.render_company_search()}
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col sm={2}>
                                <div className="icon_row icon_col">
                                    <FaImage className="icon" size={25} />
                                </div>
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Company Name", data: this.form.name})}
                                {this.render_labeled_input({label: "Motor Carrier #", data: this.form.mc_number})}
                                {this.render_labeled_input({label: "DOT #", data: this.form.dot_number})}
                            </Col>
                            <Col xs="auto"/>
                            <Col className="d-flex flex-column-reverse">
                                {this.render_labeled_input({label: "Email Address", data: this.form.email})}
                                {this.render_labeled_input({label: "Phone", data: this.form.phone})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="padding_row mb-3 mt-1">
                            <Col className="title_2">
                                Billing Details
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col>
                                {this.render_labeled_input({label: "Address", data: this.form.address})}
                                {this.render_labeled_input({label: "Address Line 2", data: this.form.address_2})}
                                {this.render_labeled_input({label: "City", data: this.form.city})}
                                <Row>
                                    <Col>
                                        {this.render_labeled_input({label: "State/Province", data: this.form.state, options: this.states})}
                                    </Col>
                                    <Col>
                                        {this.render_labeled_input({label: "Zip Code", data: this.form.zip})}
                                    </Col>
                                </Row>
                                {this.render_labeled_input({label: "Country", data: this.form.country, options: countriesOptions})}
                            </Col>
                            <Col sm={1}/>
                            <Col>
                                {this.render_labeled_input({label: "Fax", data: this.form.fax})}
                                {this.render_labeled_input({label: "Website", data: this.form.website})}
                            </Col>
                        </Row>
                        {this.render_edit_buttons()}
                    </Col>
                </Row>
            </div>
        )
    }
}