// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import resource_request from "../../../../assets/graphql/Upload/web_resource.graphql.json"
import delete_request from "../../../../assets/graphql/WebResource/webresource.graphql.json"

export default class Upload extends _baseApiObject {
    constructor(props) {
        super(props)
    }
    async fetch_files({caller, endpoint, callback, params, id}) {
        if(endpoint === undefined) { return }
        let body = JSON.parse(JSON.stringify(resource_request))
        body.query = body.query.replace("web_resource", 'web_resources(link: { endpoint: "'+endpoint+'", id: "'+id+'" })') 
        params = (params === undefined) ? {} : params
        params.body = body
        let results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        //  REFACTOR
        caller.view.state.files = []
        if(results?.data?.web_resources !== undefined) {
            if(results.data.web_resources?.length === 0) { return }
            // move map into a callback?
            results.data.web_resources?.map(async (resource, index) => {
                return await this.file_download({caller: caller, value: resource})
            })
        }
    }   
    async file_download({caller, url, is_private, callback, params}) {
        if(url === undefined) { return }
        params = (params === undefined) ? {} : params
        // if it comes from create web resource which means the caller has to access the view
        // from the controller.            
        let s3_object_name = url.slice(url.indexOf(".com/")+5) // LAZY
        let body = {
            file: {
                name: s3_object_name,
                size: 1234 // arbitrary size to trick nifi
            },
            put: false,
            private: is_private
        }
        params.request_type = "file"
        return await this.state.parent.file_post({server: "dev_upload", body: body, caller: caller, callback: callback, params: params})
    }
    async file_upload({caller, body, callback, params, id}) {
        body.put = true
        return await this.state.parent.file_post({server: "dev_upload", body: body, callback: callback, caller: caller})
    }
    async file_delete({caller, value, callback, params, id}) {
        if (id === undefined) { return }
        let body = JSON.parse(JSON.stringify(delete_request))
        body.query = body.query.replace("removeWeb_resource", 'removeWeb_resource(input: { id: "'+id+'" })')
        return await this.state.parent.post({body: body, callback: callback, caller: caller})
    }
    async file_update({caller, value, body, callback, params, id}) {
        await this.file_delete({caller: caller, value: value})
        await this.file_upload({caller: caller, body: body, callback: callback})
        caller.view.forceUpdate()
    }
    // this doesnt work here, maybe in another object like a modal?
    // was to be called where body is being defined in admin controller browse function.
    create_upload_body({file, docType, privy, endpoint, id}) {
        let body = {
            file: file,
            docType: docType,
            private: privy, // Boolean
            endpoint: endpoint,
            id: id 
        }
        return body
    }
}
