// GENERAL REACT MODULES
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaPen, FaRegWindowClose } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import _basePartial from "components/General/_basePartial.jsx"
import "./Shipper.css"
import config_data from "./Shipper.json"
import Controller from "./ShipperPartial.controller.jsx"
import Modal from "./Shipper.modal.jsx";

export default class ShipperObject extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form         = config_data.form_objects
        this.configs            = config_data.configs
        this.state.padding      = (this.state?.padding) ? "ps-3" : ""
        this.state.border       = (this.state?.borderless) ? "" : "cota_panel_object_unsized cota_panel_border"
        this.state.seperated    = true
	    this._draggable_modal   = false
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
	    this._modal             = Modal
    }
    follow_on_selection({event, obj, data}) {
        this.controller.setState({key: data?.title, param: data?.name, value: {search_value: ""}})
        this.api?.contacts?.gid({caller: this, value: obj?.id, callback: {f: this.process_loaded_contact, p: {name: data?.name}}})
    }
    process_loaded_contact({caller, params, results}) {
        caller.controller.view.state.data.stops.push(results?.data?.contact)
        caller.controller.view.forceUpdate()
        caller.state.parent?.column?.forceUpdate()
    }
    get details() {
        return this.partial_controller.details
    }
    get contact() {
        return this.details?.contact
    }
    get company() {
        return this.contact?.company
    }
    followDidMount() {
	    this.partial_controller?.load_cache()
    }
    determine_edit_contact() {
        if(this.state.modal) {
            return this.state.parent.toggle_modal({toggle_state: true})
        }
        const editContact = this.partial_controller?.editContact.bind(this)
        return editContact()
    }
    selectRender() {
        let display = true
        if (this.state.visible !== undefined) {
            let obj = this.controller.getState({key: "data", param: this.state.visible})
            if (obj?.id === undefined) { display = false }
        }
	    if (!display) { return (<></>) }
        if (this.contact?.id !== undefined) {
            return this.renderDetails(this.details)
        }
        return this.renderInput()
    }
    renderTitle() {
        if(!this.state.title) {return <></>}
        return (
            <Row className={"font_size_25 "}>
                <Col className={(this.state.type=== "stop") ? "large_title" : ""}>
                    {(this.state.type === "stop") ? ("Add " + this.configs?.[this.state.type]?.title +"s ?") : this.configs?.[this.state.type]?.title}
                </Col>
            </Row>
        )
    }
    renderSubPanel() {
        if(!this.state.title) {return <></>}
        return (
            <Col sm={1} className="sub_subpanel center_text">
                {this.configs?.[this.state.type]?.title} 
            </Col>
        )
    }
    renderInput() {
        return (
            <Row className={this.state.padding + " shipper_panel g-0"} id={this.name} ref={ (divElement) => { this.divElement = divElement } }>
                <Col className={this.state.border + " adj_padding"}>
                    {this.renderTitle()}
                    <Row>
                        <Col>
                            {this.controller.view.formObject({data: this.state.form[this.state.type], 
                                                                options: undefined, 
                                                                owner: this})}
                        </Col>
                        <Col className="create_contact_col">
                            <Button className={"toggle_button_style "+this.controller.getTheme()} 
                                onClick={() => this.determine_edit_contact()}>
                                Create Contact
                            </Button>
                        </Col>
                    </Row>
                </Col>
                {this.renderSubPanel()}
            </Row>
        )
    }
    render_reset_rep() {
        if(this.state.no_edit) {return <></>}
        return (
            <Col className="g-0">
                <FaRegWindowClose onClick={this.partial_controller.reset_rep.bind(this)} className="pointer"/>
            </Col>
        )
    }
    render_contact(contact) {
        if (contact?.id === undefined || contact?.id === null) {
            return this.renderContactInput()
        }
        return (
            <Row>
                <Col>
                    {contact?.first_name}
                </Col>
                <Col>
                    {contact?.last_name}
                </Col>
                <Col>
                    {contact?.email}
                </Col>
                <Col>
                    {contact?.phone}
                </Col>
                <Col sm={1}>
                    <Row>
                        <Col className="g-0">
                            &nbsp;
                        </Col>
                        {this.render_reset_rep()}
                        {/* <Col className="g-0">
                            <FaRegWindowClose onClick={this.partial_controller.reset_rep.bind(this)} className="pointer"/>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        )
    }
    renderContactInput() {
        if(this.state.no_rep) {return <></>}
        return (
            <Row>
                <Col>
                    <Button className={"toggle_button_style "+this.controller.getTheme()} onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                        Add Representative?
                    </Button>
                </Col>
            </Row>
        )
    }
    renderContactDetails(data) {
        return (
            <>
                <Row className="g-0">
                    <Col>
                        {data?.first_name} {data?.last_name}
                    </Col>
                    <Col>
                        {data?.email} 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data?.phone} 
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </>
        )
    }
    processDate({date, word}) {
        if (date !== undefined) {
            var splitStr = (""+date+"").split(word);
            return splitStr[0].substring(4)
        }
        return ""
    }
    renderDetails(data) {
        return (
            <Row className={this.state.padding + " subpanel g-0 partial_text"} id="ShipperObject" ref={ (divElement) => { this.divElement = divElement } }>
            <Col className={this.state.border + " max_height  relative bg" }>
                    <Row>
                        <Col>
                            <Row className="g-0" >
                                <Col >
                                    <Row className="g-0" >
                                        <Col>
                                            {this.contact?.name}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            {this?.contact?.address}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            {this?.contact?.address_2}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            {this.contact?.location}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="g-0" >
                                        <Col>
                                            &nbsp;
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            {this?.company?.phone}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            {this?.company?.email}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
                                            &nbsp;
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="g-0" >
                                <Col>
                                    <Row className="g-0" >
                                        <Col>
                                            {this?.contact?.hours.open}
                                        </Col>
                                        <Col>
                                            -
                                        </Col>
                                        <Col>
                                            {this?.contact?.hours.close}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="g-0" >
                                        <Col>
                                            Appointment {((this.details?.appointment_required) ? " Required" : "")}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        <Col>
					                        {this.partial_controller?.process_date({date: this.details?.date, word: "T"})}
                                        </Col>
                                        <Col>
                                            {this.details?.time}
                                        </Col>
                                    </Row>
                                    <Row className="g-0" >
                                        Pickup # {this.details?.number}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}>
                            <Row>
                                {this.render_edit()}
                                {this.render_close()}
                            </Row>
                        </Col>
                    </Row>
		            {
                        this.render_contact(this.details?.rep)
                    }
                </Col>
                {this.renderSubPanel()}
            </Row>
        )
    }
    render_close() {
        if(this.state.no_close) {return <></>} 
        return (
            <Col className="g-0">
                <FaRegWindowClose onClick={this.partial_controller.reset_contact.bind(this)} className="pointer"/>
            </Col>
        )
    }
    render_edit() {
        if(this.state.no_edit) {return <></>}
        return (
            <Col className="g-0">
                <FaPen onClick={() => this.state.parent.toggle_modal({toggle_state: true})} className="pointer"/>
            </Col>
        )
    }
    renderView() {
console.log("----------------- RENDER STOP ---------------------")
console.log(this)
        if (this.state.type === "stops") {
            console.log(" ---------- LOAD DETAILS ----------- ")
            // console.log(this)
        }
        this.partial_controller.load_details()
        this.state.visible = this.props.params?.visible
        this.state.type = this.props.params?.type
        if (this.details !== undefined) {
            this.details._param = this.state?.type
        }
        this.state._completed = this.details?.contact?._any
        return (
            <>
                {
                    this.selectRender()
                }
            </>
        )
    }
}
