// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import company_update from "assets/graphql/Companies/Company.update.mutation.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"

export default class CompanyInfoPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this,
                                                            is_view: true,
                                                            controller: this}})
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    get_company_data() {
        if(JSON.stringify(this.view.state?.data?.company) === "{}") {
            let company = this?.controller?.view?.state?.data?.company
            if(company === undefined) { return }
            company = JSON.parse(JSON.stringify(company))
            this.view.state.data.company = company
            this.set_search(company)
        }
    }
    save() {
        let data = JSON.parse(JSON.stringify(this.company?.summary))
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(company_update))
        body.query = body.query.replace("input:", "input: "+data)
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_save}})
    }
    process_save({results, caller, params}) {
        if(results?.errors !== undefined) {
            console.log("Error updating company", results.errors)
            return
        }
        let company = results?.data?.updateCompany
        caller.controller.view.state.data.company = company
        caller.set_search(company)
        caller.company._sync({data: company})
    }
    reset() {
        let company = JSON.parse(JSON.stringify(this.controller.view.state.data.company))
        this.set_search(company)
        this.company._sync({data: company})
    }
    set_search(obj) {
        this.view.state.search = {
            address: {selected_value: obj?.address},
            zip: {search_value: obj?.address?.location?.zip}
        }
    }
    follow_on_selection({event, obj, data}) {
	    console.log("--- FOLLOW ON SELECTION ---")
        if (data?.name === "zip") { 
            this.company.zip        = obj.zip
            this.company.city       = obj.city
            this.company.state      = obj.state
            this.company.country    = obj.country
        }
        if (data?.name === "address") {
            this.company.address    = obj?.address
            this.company.address_2  = obj?.address_2
            this.company.zip        = obj?.location?.zip
            this.company.city       = obj?.location?.city
            this.company.state      = obj?.location?.state
            this.company.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
            this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_company({caller, params, results}) {
        delete caller.view.state.search
        delete caller.view.state.data.companies
        let company = results?.data?.company
        caller.controller.view.state.data.company = company
        caller.set_search(company)
        caller.company._sync({data: company})
        caller.controller.view.forceUpdate()
    }
}