// GENERAL REACT MODULES
import { Button } from "react-bootstrap";

// ALL OTHER MODULES
import _viewController from "controllers/ViewController/_viewController";

export default class BackgroundController extends _viewController {
    constructor(props) {
        super(props)
    }
    renderLogout() {
        if (this.state?.supportLogout) {
            return (
                <div>
                    <Button variant="primary" className="RoundedButton">Logout</Button>
                </div>
            )
        }
        return (
            <span />
        )
    }
}