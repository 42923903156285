import _baseController from "components/General/_baseController"

export default class _ModelController extends _baseController {
    constructor(props) {
        super(props)
        this.is_view	= false
        this._nested    = undefined
        if (props !== undefined) {
            if (props?.params !== undefined) {
                Object.keys(props?.params).map((param, _) => {
                    this[param] = props?.params[param]
                    return undefined
                })
            }
        }
        this.set_value = this.set_value.bind(this)
    }
    is_bound(binded) {
        if (this._binded !== undefined) {
            return this._binded
        }
        if (binded !== undefined) {
            return binded
        }
        return false
    }
    get _name() {
        return this.constructor.name.toLowerCase().replace("modelcontroller", "")
    }
    get _data_param() {
        let param = this._name
        if (this._param !== undefined) {
            param = this._param
            // return (this._nested !== undefined) ? this._nested+"."+this._param : this._param
	    }
        if (this._nested !== undefined) {
            param = this._nested +"."+ param
        }
        return param
    }
    set_value({key, param, value, index}) {
        param = (param === undefined) ? key : param
        // If the ModelController does not not use the customer Setter unless the customer Getter returns something other than undefined
        Object.byString({obj: (this[param] !== undefined) ? this : this.data,
                                obj_string: param,
                                obj_value: value});
        this.controller.view.forceUpdate()
        this.update_follow_on()
    }
    update_follow_on() {

    }
    // update({key, param, value, index}) {
    //     index = (this.index !== undefined && !isNaN(this.index)) ? this.index : index
    //     if (index === undefined) {
    //         this.set_value({key: key, param: param, value: value, index: this.index})
    //     } else {
    //         if (this.controller?.view?.state?.[key]?.[this._name]?.[index] !== undefined) {
    //             this.controller.view.state[key][this._name][index] = value
    //         }
    //     }
    // }
    update({key, param, value, index}) {
        index = (this.index !== undefined && !isNaN(this.index)) ? this.index : index
        if (index === undefined || index === null) {
            this.set_value({key: key, param: param, value: value, index: this.index})
        } else {
            if (param !== undefined) {
                if (this.controller?.[key] !== undefined) {
                    if (Array.isArray(this.controller?.[key])) {
                        this.controller[key][index][param] = value
                    } else {
                        this.controller[key][param] = value
                    }
                }
            }
            if (this.controller?.view?.state?.[key]?.[this._name]?.[index] !== undefined) {
                this.controller.view.state[key][this._name][index] = value
            }
        }
    }


    set data(value) {
        if (this.controller !== undefined) {
            if (this._data_key === undefined) {
                if (this.index !== undefined) {
                    let data = this.controller?.[this._data_param]
                    if (Array.isArray(data)) {
                        data[this.index] = value
                        this.controller[this._data_param] = data
                    }
                } else {
                    this.controller[this._data_param] = value
                }
            }
            if (this._data_param  === "") {
                this.controller.setState({key: this._data_key, value: value})
            } else {
                if (this.index !== undefined && this._nested !== undefined) {
                   let data = this.controller.getState({key: this._data_key, param: this._nested})
                    data[this.index] = value
                    this.controller.setState({key: this._data_key, param: this._nested, value: data})
                } else {
                    this.controller.setState({key: this._data_key, param: this._data_param, value: value})
                }
            }
        }
    }
    get data() {
        let data = this?._state?.data
        if (data === undefined && this.controller !== undefined) {
            if (this._data_key === "") {
                if (this.index !== undefined) {
                    return this.controller?.[this._data_param]?.[this.index]
                } else {
                    return this.controller?.[this._data_param]
                }
            }
            if (this._data_param  === "") {
                data = this.controller.getState({key: this._data_key})
            } else {
                if (this._binded) {
                    data = this.parent.data?.[this._param]
                    return data
                }
                if (this.index !== undefined && this._nested !== undefined) {
                    data = this.controller.getState({key: this._data_key, param: this._nested})
                    data = data?.[this.index]
                    if (this?._param !== undefined) {
                        data = data?.[this?._param]
                    }
                } else {
                    data = this.controller.getState({key: this._data_key, param: this._data_param})
                }
	        }
            if (this.constructor.name === "StopModelController" && this._nested === "stops") {
                console.log(this)
            }
        }
        data = (Array.isArray(data) && !isNaN(this.index)) ? data[this.index] : data
//	data = (Array.isArray(data)) ? data[((this.index !== undefined) ? this.index: 0)] : data
        return data
    }
    _load({data, key, sub_objects}) {
        this._state         = {}
        this._state.data    = data
        this._state.key     = key
        if (sub_objects !== undefined) {
                Object.keys(sub_objects).map((key, index) => {
                    this.load_sub_object({[key]: sub_objects[key]})
                })
        }
        return this
    }
    _sync({data, remapper}) {
        if (data !== undefined && data !== null) {
            let json = JSON.parse(JSON.stringify(data))
	        let remap = undefined
            if (json !== undefined) {
                if (this.remappers?.[remapper] !== undefined) {
                    remap = this.remappers?.[remapper]
                }
                if (remap !== undefined && remap !== null) {
                    Object.keys(remap).map((k, i) => {
                        if (Array.isArray(data)) {
                            json.map((item, ii) => {
                                item = this._remap({data: item, remap: remap, k: k})
                            })
                        } else {
                            json = this._remap({data: json, remap: remap, k: k})
                        }
                    })
                }
            }
            this.setState({value: json})
            this._subobjs({data: json, remap: remap})
        }
    }
    _remap({data, remap, k}) {
        if (data?.[k] !== undefined && data?.[k] !== null) {
            data[remap[k]] = JSON.parse(JSON.stringify(data?.[k]))
            delete data[k]
        }
        return data
    }
    _subobjs({data, remap}) {
        if (data !== undefined && data !== null) {
            Object.keys(data).map((k, i) => {
                // DO NOT REMOVE THE FOLLOWING LINE
                let _ = this[k]
                // This exists to trigger any auto-generation inside the ModelController
                if (typeof data?.[k] === "object") {
                    if (typeof this[k]?._sync === "function") {
                        (this[k])?._sync({data: data?.[k], remapper: remap})
                    }
                }
            })
        }
    }
    _find(index) {
        this.index = index
        return this
    }
    load_sub_object(sub_objects) {
        Object.keys(sub_objects).map((key, index) => {
            this[key]?.load({data: sub_objects[key]})
            return undefined
        })
    }
    get all() {
        return this.data
    }
    get _id() {
        return (this.data?._id !== undefined) ? this.data?._id : this.data?.id
    }
    set _id(value) {
        this.data._id = value
    }
    get _sum() {
        let filled = 0
        if (this?.data !== undefined && this?.data !== null) {
            Object.values(this?.data).map((value, index) => {
                if (value !== undefined) {
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            filled = filled + 1
                        }
                    } else {
                        filled = filled + 1
                    }
                }
            })
        }
        return filled
    }
    get _filled() {
        if (this._sum === Object.keys(this.data).length) {
            return true
        }
        return false
    }
    get _any() {
        if (this._sum > 0) {
            return true
        }
        return false
    }
    get _data_key() {
        return (this.key === undefined) ? "data" : this.key
    }
    currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    setState({key, param, value}) {
        key = (key === undefined) ? this._data_key : key
        param = (param === undefined) ? this._data_param : this._data_param+"."+param
        this.controller.setState({key: key, param: param, value: value})
    }
    get _raw_data() {
        let data = this?._state?.data
        if (data === undefined && this.controller !== undefined) {
            if (this._data_param  === "") {
                data = this.controller.getState({key: this._data_key})
            } else {
                if (this.index !== undefined && this._nested !== undefined) {
                    data = this.controller.getState({key: this._data_key, param: this._nested})
                    data = data?.[this.index]
                    if (this?._param !== undefined) {
                        data = data?.[this?._param]
                    }
                } else {
                    data = this.controller.getState({key: this._data_key, param: this._data_param})
                }
            }
        }
        return data
    }
    nav(dir) {
        if (!isNaN(this.index)) {
            if (dir) {
                if (this.index+1 < this._raw_data.length) {
                    this.index = this.index + 1
                } else {
                    this.index = 0
                }
            } else {
                if (this.index > 0) {
                    this.index = this.index - 1
                } else {
                    this.index = this._raw_data.length-1
                }
            }
        }
	    this.controller.view.forceUpdate()
    }
}

String.prototype.Capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.phone = function() {
    var cleaned = ('' + this).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return this
}
Object.prototype.copy = function() {
    try {
        return JSON.parse(JSON.stringify(this))
    } catch (e) {
        return this
    }
}
Object.prototype.underline = function() {
    return this.replace(/ /g, '_');
}
