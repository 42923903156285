// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { BsImage } from "react-icons/bs";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./DocView.css"
import Controller from "components/FileUpload/Upload.partial.controller";

// import NotepadImage from "../assets/notepad.jpg"

export default class DocView extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this.state.image = ""
        this.state.document = props.params?.document
        this.state.index = props.params?.index
    }
    renderView() {
        console.log(this)
        return (
            <div key={"doc_view"+this.state.document?.id} id={this.name}>
                <Row className="g-0 docview_wrapper">
                    <Col>
                        <Row className="g-0 docview_image_container">
                            <Col className="g-0 relative docview_image_fill">
                                {/* <img className="docview_image" src="" alt={NotepadImage} /> */}
                                {/* <img className="docview_image" src={`data:image/jpeg;base64,${this.state.document.encoded}`} alt="A picture of a Document" /> */}
                                <BsImage size={70} className="image_icon" />
                            </Col>
                        </Row>
                        <Row className="docview_title">
                            {/* Document Title */}
                            {this.state?.document?.name}
                        </Row>
                        {/* <Row className="docview_button_row">
                            <Col className="g-0 docview_button_update">
                                <input type="file" className="none_display" id={"actual-btn "+`${this.state.filenum}`} onChange={(e) => this.partial_controller.update_file(e, this.state.file, this.state.filenum)}/>
                                <label htmlFor={"actual-btn "+`${this.state.filenum}`} className={"btn btn-primary "+this._theme}>
                                    Update
                                </label>
                            </Col>
                            <Col className="g-0 docview_button_close" onClick={() => {this.partial_controller.handle_doc_delete(this.state.file, this.state.filenum)}}>
                                x
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </div>
        )
    }
}
