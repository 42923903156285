// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import InfoModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"
import DriverModelController from "controllers/ModelControllers/DriverModelController.jsx"
import ShipperObject from "components/NewLoadPartials/ShipperObject/Shipper.partial.jsx"

import existing_quoteload from "assets/graphql/Loads/load.query.full.graphql.json"
import edit_load from "assets/graphql/Loads/loads.mutation.graphql.json"
import load_rate_items from "assets/graphql/Loads/load.mutation.rate_items.graphql.json"
import edit_load_notes from "assets/graphql/Loads/loads.mutation.notes.graphql.json"
import edit_load_stops from "assets/graphql/Loads/load.mutation.stops.graphql.json"
import edit_load_payor from "assets/graphql/Loads/loads_mutations/load.mutation.payor.graphql.json"

export default class ViewLoadController extends _viewController {
    constructor(props) {
        super(props)
        this.overrides = ["id"]
        this.load           = new ShipmentModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.commodity      = new CommodityModelController ({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "pickup"}})
        this.consignee      = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "consignee"}})
        this.company        = new CompanyModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.line_item      = new LineItemModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true,
                                                            //  _param: "line_items"
                                                            }})
        this.information    = new InfoModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
 
                                                 
        this.requests  = [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                    endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                            {callback: {p: {name: "trailer_types", var: "trailer_types"}}, endpoint: "trailers"},
                            {callback: {p: {name: "special_requirements", var: "special_requirements"}},
                                endpoint: "requirements", req_name: "special"},
                            {callback: {p: {name: "status", var: "statuses", disp: "label"}}, endpoint: "status", 
                                        replace: {o: "status", r: 'status(types:"load")'}}]
    }
      get_id(){
        let url = window.location.pathname
        // if (process.env.REACT_APP_DEVMODE){
        //     url = "https:///mobile.cotasystems.com/stop/995d9926-954a-43b5-b754-cfdb8ff917db"
        // }
        let id = url.substring(url.lastIndexOf("/") + 1)
        return id
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            if (Array.isArray(results.data.areas)) { 
                results.data.areas.map((area, index) => {
                    caller.view.state.states.push({ value: area.state, label: area.state })
                })
            }
        }
    }
    get states() {
        return this.view.state.states
    }
    get trailer_types() {
        return this.view.state.trailer_types
    }
    get statuses() {
        return this.view.state.statuses
    }
    get requirements() {
        return this.view.state.special_requirements
    }
    get notes() {
        return this.view?.state?.data?.shipment?.notes
    }
    get line_items() {
        return this.view.state.data.line_items
    }
    get commodities() {
        let commodities = this.view?.state?.data?.commodity
        if(JSON.stringify(commodities) === '{}' || commodities === undefined) {
            return []
        }
        return commodities
    }
    get info() {
        return this.view.state.data.information
    }
    get special_requirements() {
        return this.equipment?.requirements
    }
    get commodities_dims() {
        return this.calculate_commodity_totals()
    }
    get editable() {
        return this.view.state.edit
    }
    get labeled_dims() {
        let dims = this.calculate_commodity_totals()
        dims.height = this.reduce_size({value: dims.height, metric: false, label: true})
        dims.width  = this.reduce_size({value: dims.width, metric: false, label: true})
        dims.length = this.reduce_size({value: dims.length, metric: false, label: true})
        return dims
    }
    calculate_commodity_totals() {
        let totals = { 
            height: 0, length: 0,
            width: 0, weight: 0,
            value: 0
        }
        this.commodities?.map((com, index) => {
            totals.height += com?.height ?? 0
            totals.length += com?.length ?? 0
            totals.width += com?.width ?? 0
            totals.weight += com?.weight ?? 0
            totals.value += com?.value ?? 0
        })
        return totals
    }
    reduce_size({value, metric, label}) {
        if (value !== undefined) {
            if (metric) {
                // Finish later
                return value + ((label) ? " mm": "")
            } else {
                if (value < 12) {
                    return value + ((label) ? " in": "")
                } else {
                    return (Math.round((value / 12 * 10)) / 10) + ((label) ? " ft": "")
                }
            }
        }
    }
    follow_on_selection({event, obj, data}) {
        // follow on selection runs only if the user needs to 'update' the load,
        // so set in state the variable update to true
        this.view.setState({update: true})
	    this.get_contact({id: obj?.id, name: data?.name})
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
    update(param) {
        // need to get rid of redundancy here. Almost no point to update function.
        this.save_load(param)
        // After the load is saved set update state back to false.
        this.view.setState({update: false})
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    bill_action() {
        this.view.state?.parent?.selectPanel({panel_num: 2, cache_data: {}})
    }
    dispatch_action() {
        this.view.state?.parent?.selectPanel({panel_num: 3, cache_data: {}})
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_params.controller.view.resetScreen()
    }
 load_harness() {    
      let id = this.get_id()
      console.log(`CHECKING THE ID ${id}`)
        this.load_existing_quote({id:id})
   

    }
   resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    async load_existing_quote(data) {
console.log("------------- LOAD EXISTING QUOTE -----------------")
console.log(data)
        this.setState({_is_mutating: true})
        if (data !== undefined) {
	        let body    = JSON.parse(JSON.stringify(existing_quoteload))
            let query_param = (data?.id !== undefined) ? 'load(id: "'+data?.id+'")' : 'load(load_number: "'+data?.load_number+'")'
            body.query  = body.query.replace("load", query_param)
            this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_loaded_quote, fin: true}})
        }
    }
    process_loaded_quote({caller, params, results}) {
        let quoteload = results?.data?.load
        if(quoteload?.info !== undefined) {
            // let obj = { container: quoteload?.container_number }
            caller.view.state.data.information = quoteload.info
        }
        if (quoteload?.carrier !== undefined) {
            caller.setState({key: "data", param: "saved.times", value: true})
            if (quoteload?.carrier?.id !== undefined) {
                caller.view.state.data.saved.dispatched = true
            }
        }
        if (quoteload?.loads?.length > 0) {
            caller.company.index = 0
            caller.view.state.data.company = quoteload?.loads.map(a => a.payor)
        }
        let quote = quoteload?.quote
        if (quoteload?._type === "quote") {
            quote = JSON.parse(JSON.stringify(quoteload))
            delete quoteload.id
        }
        if (quoteload !== undefined && quoteload !== null) {
            if (quoteload.commodities === undefined) {
                caller.commodity?._sync({data: quote?.raw_quote?.request?.items, remapper:"quote"})
            } else {
                caller.commodity?._sync({data:quoteload.commodities})
            }
            if(quoteload?.rate_items !== undefined) {
                let line_items = caller.view.state.data.line_items
		if (Array.isArray(quoteload?.rate_items) && Array.isArray(line_items)) {
                    caller.view.state.data.line_items = [...quoteload?.rate_items, ...line_items]
		}
            }
	        caller.setState({key: "data", param: "equipment", value: quoteload?.equipment})
            caller.load?._sync({data: quoteload, remapper:"quote"})
            caller.quote?._sync({data: quote})
            if (quote?.raw_quote !== undefined) {
                let raw_request = JSON.parse(JSON.stringify(quote?.raw_quote))
                let date = undefined
                if (quote?.pickup_date !== null && quote?.pickup_date !== undefined) {
                    let convert_date = new Date(parseInt(quote?.pickup_date, 10))
                    if (!isNaN(convert_date)) {
                        date = convert_date
                    }
                }
                caller.quote?._sync({data: quote})
                caller.company?._sync({data: quote?.customer})
            }
            caller.load_payor_or_dispatcher({load: quoteload})
            if (quoteload?.pickup !== undefined) {
		caller.view.state.data.pickup = quoteload?.pickup
            }
            if (quoteload?.dropoff !== undefined) {
                caller.view.state.data.consignee = quoteload?.dropoff
            }
            if (quoteload?.stops !== undefined) {
		caller.view.state.data.stops = quoteload?.stops
           }
        }
        caller.view.setState({_is_loading: false})
    }
    load_payor_or_dispatcher({load}) {
        if(load?.dispatcher !== undefined) {
            if(load.dispatcher?.roles?.find(({ name }) => name.toLowerCase() === "importer") !== undefined) {
                this.view.state.edit = false
                return this.get_company({id: load.dispatcher?.id, name: "company"})
            }
        }
        if(load?.payor !== undefined) {
            this.view.state.edit = true
            return this.get_company({id: load.payor?.id, name: "company"})
        }
        console.log("Error loading payor/dispatcher info")
    }
    get_stop({id, name, index}) {
        this.api?.stops?.gid({caller: this, value: id, callback: {f: this.process_loaded_stop, p: {name: name, index: index}}})
    }
    get_contact({id, name}) {
        this.api?.contacts?.gid({caller: this, value: id, callback: {f: this.process_loaded_contact, p: {name: name}}})
    }
    get_company({id, name}) {
        this.api?.companies?.get({caller: this, id: id, callback: {f: this.process_loaded_company, p: {name: name}}})
    }
    process_loaded_contact({caller, params, results}) {
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company,
            insurance: results?.data?.contact?.insurance
        }
        caller.setState({key: "data", param: params?.name, value: contact})
        // guard update function in an if statement to run only 
        // if variable in state called update is true.
        // prevents update function from being called when page is first loaded.
        if(caller.view.state.update) {
            caller.update()
        }
    }
    process_loaded_company({caller, params, results}) {
        caller?.[params?.name]?._sync({data: results.data.company})
    }
    // LOAD CREATION:
    async save_load(param) {
        let mutation = edit_load
        this.view.state.data.commodities = this.commodity.summary
        let summary = JSON.parse(JSON.stringify(this.load.summary))
        if(param === "notes") {
            mutation = edit_load_notes
            summary = {id: summary.id, notes: summary.notes}
        }
        if(param === "status") {
            summary = {id: summary.id, status: summary.status}
        }
        if(param === "stops") {
            mutation = edit_load_stops
            summary = {id: summary.id, stops: summary.stops}
        }
        // May need to change due to dispatcher field.
        if(param === "payor") {
            mutation = edit_load_payor
            summary = {id: summary.id, payor_id: summary.payor_id}
        }
        console.log("save load summary sls*", summary)
        let data    = this.toUnquotedJSON(summary)
        let body    = JSON.parse(JSON.stringify(mutation))
        body.query  = body.query.replace("load", 'load(input: '+data+')')
        console.log(body)
        // reset the data, may need to remove when multi commodities gets implemented
        delete this.view.state.data.commodities
        await this.api.loads.create({caller: this, params: {body: body}, callback: {f: this.process_load}})
    }
    process_load({caller, params, results}) {
        console.log(results)
        if(results?.errors !== undefined) {
            console.log("Load update error:", results.errors)
        }
        let load_result = results?.data?.load
        if(load_result?.rate_items !== undefined) {
            let line_items = results.data.load.rate_items
            line_items.push({rate: 0})
            caller.view.state.data.line_items = line_items
        }
        if(load_result?.notes !== undefined) {
            console.log("Reloading notes...")
            caller.view.state.data.shipment.notes = results.data.load.notes
        }
        if(results.data?.load?.payor !== undefined) {
            caller.get_company({id: results?.data?.load.payor?.id, name: "company"})
        }
        if(load_result?.stops !== undefined) {
            if (Array.isArray(load_result?.stops)) {
                results.data.load.stops.map((stop, index) => {
                    caller.get_stop({id: stop.id, name: "stops", index: index})
                    // caller.get_stop({id: stop.id, name: "stops", index: stop.index})
                })
            }
        }
        console.log("Load updated")
        caller.view.forceUpdate()
    }
}
