// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Form, Button } from 'react-bootstrap'
import { AiFillCloseCircle } from "react-icons/ai";

// PAGE SPECIFIC MODULES
import "./Upload.partial.css"
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Upload.partial.controller.jsx"
import Modal from "./UploadModal/Upload.modal.jsx"
import { GiConsoleController } from "react-icons/gi";

export default class UploadPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.logo                   = props.params?.logo
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
        this._modal                 = Modal
        this.state.include_title    = (this.state.include_title === undefined) ? true : this.state.include_title 
        this.state.title_row        = (this.state.title_row === undefined) ? true : this.state.title_row 
	    this.state.single_link      = (this.state.single_link === undefined) ? false : this.state.single_link
        this.state.title            = (this.state.title === undefined) ? ((!this.state.single_link) ? "Add New Documents" : "Click to Upload Photo") : this.state.title
        this.controller.setState({key: "file_endpoint", value: props.params.endpoint})
        this.state.document_types   = []
        this.state.queue            = []
        this.controller.uploader    = this.partial_controller
        this.css_rules              = props.params.css_rules
        this.image_css              = props.params.image_css
        this.id                     = props.params.id
        // PUT COMMENT HERE FOR PROP ABOUT HAS_QUEUE
        // Add a prop that can be passed in ex: has_queue: true
        // so that we can pass that to the upload call in Upload.modal.jsx line 74.
        // Then update the upload(queued) function in the upload partial controller to treat 
        // the queued parameter as a boolean. Then we can add the prop has_queue to each
        // UploadPartial component call where we want queue's to be made rather than instant uploads.
        // Reason: Queues are only created as of right now when creating a new contact, carrier, etc since
        // a new object of said type does not have an id yet until creation.
    }
    render_title() {
        return (
            <Col className="generic_center">
                {this.state.title}
            </Col>
        )
    }
    render_title_row() {
        if (this.state.title_row) {
            return (
                <Row className="g-0">
                    {this.render_title()}
                </Row>
            )
        }
    }
    render_title_col() {
        if (!this.state.title_row && this.state.include_title) {
            return this.render_title()
        } else {
            return (<Col />)
        }
    }
    render_contact_image(){
        if(this.state?.url) {
            return (<>
                <input type="file" id="pic-btn" className="none_display" onChange={this.partial_controller.upload_contact.bind(this)}/>
                    <label htmlFor="pic-btn" className="transparent_btn">
                        <div>
                        <img className = {this.image_css}   src={this.state?.url} />
                        {this.state.title}
                    </div> 
                </label> 
            </>)
        }
        return (
            <>
            <input type="file" id="pic-btn" className="none_display" onChange={this.partial_controller.upload_contact.bind(this)}/>
            <label htmlFor="pic-btn" className="transparent_btn">
                <div className={`${this.css_rules} `+this._theme} />
                {this.state.title}
            </label> 
        </>
        )
    }

    render_user_image() {
        this.state.url = this.state?.data?.user?.image?.[0]?.url
        if(this.state?.url) {
            return (<>
                <input type="file" id="pic-btn" className="none_display" onChange={this.partial_controller.upload_user.bind(this)}/>
                    <label htmlFor="pic-btn" className="transparent_btn">
                        <div>
                        <img className = {this.image_css}   src={this.state?.url} />
                        {this.state.title}
                    </div> 
                </label> 
            </>)
        }
        return (
            <>
            <input type="file" id="pic-btn" className="none_display" onChange={this.partial_controller.upload_user.bind(this)}/>
            <label htmlFor="pic-btn" className="transparent_btn">
                 <div className={`${this.css_rules} `+this._theme} />
                 {this.state.title}
        </label> 
        </>
        )
    }
    // followDidMount() {
    //     // if(!this.state.single_link) {
    //     if(this.state.single_link) {
    //         this.partial_controller.load_data()
    //     }
    // }
    render_queued() {
        if (this.state.queue.length > 0) {
            return(
                <Row>
                    <Col>
                        <Row>
                            <Col className="generic_center strong_font">
                                File Name
                            </Col>
                            <Col className="generic_center strong_font">
                                File Type
                            </Col>
                            <Col sm={1} />
                        </Row>
                        {this.state.queue?.map((queued, index) => {
                            return (
                                <Row className="queued_item" key={"queue_index_"+index}>
                                    <Col className="generic_center">
                                        {queued.file.name}
                                    </Col>
                                    <Col className="generic_center">
                                        {this.partial_controller.resolve_file_type(queued.doc_type)}
                                    </Col>
                                    <Col className="generic_center" sm={1} onClick={this.partial_controller.remove_file.bind(this, index)}>
                                        <AiFillCloseCircle size={20} className={"close"}/>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        if(this.state.single_link) {
            return (
                <Row id={this.name} className="update_logo">
                    <Col>
                    {/* <input type="file" id="pic-btn" className="none_display" onChange={this.partial_controller.browse.bind(this)}/> */}
                        {/* <label htmlFor="pic-btn" className="transparent_btn"> */}
                            {/* {this.state.endpoint === "contact" || "carrier" ? this.render_contact_image() : this.render_user_image()} */}
                            {this.state.endpoint === "contact"  ? this.render_contact_image() : this.state.endpoint === "carrier"  ? this.render_contact_image() : this.render_user_image()}
                        {/* </label> */}
                    </Col>
                        {/* {this.controller.view.state?.current_file?.name === undefined ? console.log("IT WAS NOT  DEFINED") :this.partial_controller.upload({file:this.controller.getState({key: "current_file"}),doc_type: "other",private:false, id: this.id, endpoint:"user"})} */}
                </Row>
            )
        }
        return (
            <Col id={this.name}>
		        {this.render_title_row()}
                <Row className="g-0">
		            {this.render_title_col()}
                    <Col className="doc_button_center_col">
                        <Row className="g-0">
                            <Col className="col_buffer_right">
                                {/* potentially remove this and just use basic input */}
                                <Form.Control type="text" 
                                    className={"capitalized full_border new_doc_input disabled "+this._theme} 
                                    placeholder={this.controller.view.state?.current_file?.name ?? "File Input"}
                                    disabled/>
                            </Col>
                            <Col sm={2} className="col_no_padding button_col">
                                <input type="file" id="doc-btn" className="none_display" onChange={this.partial_controller.browse.bind(this)}/>
                                <label htmlFor="doc-btn" 
                                        className={"btn btn-primary  doc_button_left primary_button_coloring full_border_wo_left toggle_button_style "+this._theme}>
                                    Browse
                                </label>
                            </Col>
                            <Col className="button_col">
                                <Button onClick={this.toggle_modal?.bind(this, {toggle_state: true})}
                                        className={" doc_button_right primary_button_coloring full_border_wo_left toggle_button_style "+this._theme}
                                        disabled={(this.controller.view.state?.current_file?.name === undefined) ? true : false}>
                                    Upload
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col />
                </Row>
		        {this.render_queued()}
            </Col>
        )
    }
}

