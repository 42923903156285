// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./Seperator.css"

export default class Seperator extends _basePartial {
    constructor(props) {
        super(props)
        if (this.state.view_controller !== undefined) {
            this.state.length = this.state?.view_controller?.state?.rows?.length
            this.state.view_controller.seperator = this
        }
    }
    determine_length({params, index}) {
        if (params?.[index+1] !== undefined) {
            if (params?.[index+1]?.status) {
                return "is_visible"
            }
        }
        return "is_hidden"
    }
    render_solid() {
        return (
            <Col className="g-0 Seperator max_block" id={this.name}>
                <Row className="g-0 max_block">
                    <Col sm={1} className="g-0 slim_column max_block" >
                        <Row className="g-0 max_block" >
                            <div className="vl_rel Seperator" />
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }
    render_ball({index}) {
        return(
            <div className={"v_ball is_visible"} key={"seperator_ball_"+index} />
        )
    }
    render_final({params, index}) {
        let finished_column = 0
        if (index === params.length-1) {
            params.map((param, index) => {
                if (param?.status) {
                    finished_column = finished_column + 1
                }
            })
        }
        if (finished_column === params.length-1) {
            return this.render_ball({index: index})
        }
    }
    render_line({index, param, params}) {
        return (
            <span key={"filled_row_"+index}>
                <Row className={"g-0 sub_subpanel_buffer "} style={{height: (param.height+20)+"px"}}>
                    <div className={"vl bottom_buffer_row "+this.determine_length({params: params, index: index})}/>
                    <div className={"vl top_buffer_row is_visible"}/>
                    { this.render_ball({index: index}) }
                </Row>
                {
                    this.render_final({params, index}) 
                }
            </span>
        )
    }
    render_empty({height, index}) {
        return (
            <Row className="g-0 company_panel_column_buffer" style={{height: height+"px"}} key={"empty_row_"+index} />
        )
    }
    render_seperated() {
        let params = this.state.view_controller?.row_states()
        return (
            <Col className="Seperator" id={this.name}>
                <Row className="g-0">
                    <Col sm={1} className="g-0 slim_column" >
                        {
                            params?.map((param, index) => {
                                if (param.status) {
                                    if (!param.seperated) {
                                        return this.render_empty({height: param.height, index: index})
                                    } else {
                                        return(
                                            this.render_line({param: param, index: index, params: params})
                                        )
                                    }
                                }
                            })
                        }
                    </Col>
                </Row>
            </Col>
        )
    }
    determine_seperator() {
        if (this.state.solid) {
            return this.render_solid()
        }
        return this.render_seperated()
    }
    render() {
        return (
            <>
                {
                    this.determine_seperator()
                }
            </>
        )
    }
}
