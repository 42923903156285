// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import RowPanel from "./RowPanel/RowPanel.partial"
import ColumnController from "components/Column/Column.controller.jsx"
import configs from "./ColumnOne.json";
import image from "assets/gears.jpg";

export default class ColumnOneController extends ColumnController {
    constructor(props) {
        super(props)
        this.configs = this.determine_configs()
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    determine_configs() {
        switch (this.view.name) {
            case "LoadboardPanel":
                return configs.loadboards.data
            case "AccountingPartial":
                return configs.accounting.data
            default:
                return configs.loadboards.data 
        }
    }
    generate_rows() {
        console.log(this)
        let rows = Array(3).fill(undefined)
        rows.map((row, index) => {
            this.configs[index].image = image
            this.configs[index].default = this.configs[index].default.replace(/\{NAME\}/g, this.configs[index].name);
            rows[index] = {obj: RowPanel, params: {params: {parent: this, 
                                controller: this.view.controller, 
                                editable: false, 
                                index: 0, 
                                data: this.configs?.[index]
                            }}}
        })
        return rows
    }
}