// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsLightbulb } from "react-icons/bs";

// MODULE SPECIFIC IMPORTS
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Controller from "./FeedBearWidget.controller.jsx"

export default class FeedBearWidget extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
    }
    followDidMount() {
        // NEED to get env variable for sso 
        let sso = (process.env.REACT_APP_FEEDBEAR !== undefined) ? process.env.REACT_APP_FEEDBEAR : null     
        // this.create_feedbear({board: "carrierpro", sso: sso})
    }
    // create_feedbear({board, sso}) {
    //     console.log("--- initializing feedbear ---")
    //     this.create_widget({f: 'https://sdk.feedbear.com/widget.js'})
    //     FeedBear("button", {
    //         element: document.querySelector("[data-feedbear-button]"),
    //         project: "cota-systems",
    //         board: board,
    //         ssoToken: sso
    //     });
    // }
    create_widget({f, js, fjs}) {
        let obj = "FeedBear", el = "script"
        window[obj] = window[obj] || function () { 
            (window[obj].q = window[obj].q || []).push(arguments) 
        }
        js = document.createElement(el) 
        fjs = document.getElementsByTagName(el)[0]
        js.id = obj; js.src = f; js.async = 1
        fjs.parentNode.insertBefore(js, fjs) 
    }
    renderView() {
        return ( 
            // <BsLightbulb className="menuListIcon"/>
            <Row id={this.name}>
                <Col>
                    <BsLightbulb className="menuListIcon" data-feedbear-button/>
                    {/* <button data-feedbear-button>Feedback?</button> */}
                </Col>
            </Row>
        )
    }
}