// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class LoadDetailsController extends _viewController {
    constructor (props) {
        super(props) 
    }
    get commodity_value() {
        let total = this.controller?.commodities_dims.value
        if(total === 0) {
            return total = "N/A"
        }
        return "$" + total
    }
    get total_value() {
        let total = this.controller?.load?.data?.total_amount
        if(total === null || total === undefined) {
            return total = "N/A"
        }
        return "$" + total
    }
    get_status_css({status}) {
        switch(status) {
            case "billed":
                return "billed"
            default:
                return "available"
        }
    }
}