// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";

export default class InsuranceModelController extends _ModelController {
    constructor(props) {
        super(props)
    }
    get producer() {
        return this.data?.producer
    }
    set producer(value) {
        this.controller.setState({key: "data", param: "insurance.producer", value: value})
    }
    get broker_name() {
        return this.data?.name
    }
    set broker_name(value) {
        this.controller.setState({key: "data", param: "insurance.name", value: value})
    }
    get email() {
        return this.data?.email
    }
    set email(value) {
        this.controller.setState({key: "data", param: "insurance.email", value: value})
    }
    get phone() {
        return this.data?.phone
    }
    set phone(value) {
        this.controller.setState({key: "data", param: "insurance.phone", value: value})
    }
    get phone_flat() {
        let country_code    = (this.data?.phone?.country_code === undefined) ? "" : this.data.phone.country_code
        let area_code       = (this.data?.phone?.area_code === undefined) ? "" : this.data.phone.area_code
        let phone_number    = (this.data?.phone?.phone_number === undefined) ? "" : this.data.phone.phone_number
        if (country_code === "" && area_code === "" && phone_number === "") {
            return ""
        }
        return "+"+country_code+"-"+area_code+"-"+phone_number
    }
    get general_liability() {
        return this.data?.general_liability
    }
    set general_liability(value) {
        this.controller.setState({key: "data", param: "insurance.general_liability", value: value})
    }
    get cargo_liability() {
        return this.data?.cargo_liability
    }
    set cargo_liability(value) {
        this.controller.setState({key: "data", param: "insurance.cargo_liability", value: value})
    }
    get workmens_comp() {
        return this.data?.workmens_comp
    }
    set workmens_comp(value) {
        this.controller.setState({key: "data", param: "insurance.workmens_comp", value: value})
    }
    get expiration() {
        return this.data?.expiration
    }
    set expiration(value) {
        this.controller.setState({key: "data", param: "insurance.expiration", value: value})
    }
    get all() {
        return this.data
    }
    get summary() {
        let _summary = {}
        if (this._id !== undefined) {
            _summary.id = this._id
        }
        if (this.producer !== undefined) {
            _summary.producer = this.producer
        }
        if (this.broker_name !== undefined) {
            _summary.name = this.broker_name
        }
        if (this.email !== undefined) {
            _summary.email = this.email
        }
        if (this.phone !== undefined) {
            _summary.phone = this.phone
        }
        if (this.phone !== undefined && this.phone !== null) {
            _summary.phone = {
                country_code:1,
                area_code: parseInt(this.phone.substring(0, 3)),
                phone_number: this.phone.substring(3, this.phone.length)
            }
        }
        if (this.general_liability !== undefined && this.general_liability != null) {
            _summary.general_liability = this.general_liability
        }
        if (this.cargo_liability !== undefined && this.cargo_liability !== null) {
            _summary.cargo_liability = this.cargo_liability
        }
        if (this.workmens_comp !== undefined && this.workmens_comp !== null) {
            _summary.workmens_comp = this.workmens_comp
        }
        if (this.expiration !== undefined && this.expiration !== null) {
            _summary.expiration = this.expiration.toString()
        }
        return _summary
    }
}

