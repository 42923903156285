// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";
import { HiOutlineRefresh } from "react-icons/hi";

// PAGE SPECIFIC MODULES
import "./GrafanaFrame.css"
import "./spinner.css"
import Controller from "./GrafanaFrame.controller.jsx";
import config_data from "./GrafanaFrame.json"
import { rowOptions, refreshOptions } from "./Options.js"

// ALL OTHER MODULES
import _baseViewComponent from "../General/_baseViewComponent.jsx"
var attached = false;

export default class GrafanaFrame extends _baseViewComponent {
    constructor(props) {
        super(props)
        this._isMounted             = false;
        this.state.exists           = undefined
	    this.state.channel_name     = ["grafana_loader", "grafana_nav"]
        this.state.use_control_bar  = (props?.params?.use_control_bar !== undefined) ? props.params.use_control_bar : true
        this.state.skip_next    = false
        this.partial_controller = new Controller({params: {view: this}})
        let subdomain           = (process.env.REACT_APP_NOLOGIN) ? "boards" : "grafana"
        this.state.form         = config_data.form_objects
        this.state.configs      = {rows: 20, refresh: -1}
        if (this.state?.panel_name !== undefined && this.state?.panel_configs !== undefined) {
            this.state.url = "https://"+subdomain+"."+process.env.REACT_APP_DOMAIN+"/d-solo/"+this.state?.panel_configs[this.state?.panel_name]?.code+"/"+this.state?.panel_configs[this.state?.panel_name]?.dashboard+"?orgId="+this.state?.panel_configs[this.state?.panel_name]?.orgid+"&panelId="+this.state?.panel_configs[this.state?.panel_name]?.panelid+"&theme=light"
        }
	    this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    get subdomain() {
        let subdomain   = (process.env.REACT_APP_NOLOGIN) ? "boards" : "grafana"
        if (this.state.subdomain === undefined) {
            return subdomain
        }
        return (this.state.subdomain !== undefined) ? this.state.subdomain : "grafana"
    }
    followDidMount() {
        this.cntr.grafana_frame = this
        this._isMounted = true
        this.setState({loading: true});
	    this.mount_listener()
    }
    mount_listener() {
        if (!attached) {
            window.addEventListener('message', this.handleProcessEvent);
            attached = true
        }
    }
    handleProcessEvent = event => {
        if (event.data !== undefined) {
            const data = event.data;
            try {
                const decoded = JSON.parse(data);
                if (Array.isArray(this.state?.channel_name)) {
                        if (this.state?.channel_name.indexOf(decoded?.channel) > -1) {
                                this.partial_controller?.processEventReaction(decoded)
                        }
                }
                if (decoded?.channel === this.state?.channel_name) {
                    this.partial_controller?.processEventReaction(decoded)
                }
            } catch (e) { }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
	    attached = false
    }
    async hide_spinner() {
        await new Promise(resolve => setTimeout(resolve, 1500));
        if (this.state.loading === true && this._isMounted) {
            this.setState({loading: false});
        }
    };
    get_spinner_state() {
        if (this.state.loading) {
            return "show"
        }
        return "hide"
    }
    get_control_bar() {
        if (this.state.use_control_bar) {
            return (
                <Row className="grafana_control_bar g-0">
                    <Col>
                        
                    </Col>
                    <Col sm={1} className="grafana_control_label">
                        Rows per Page:
                    </Col>
                    <Col sm={1}>
                        {this.formObject({data: this.state.form.rows, options: rowOptions, owner: this, controller: this.partial_controller})}
                    </Col>
                    <Col sm={1} className="grafana_control_label">
                        Refresh Time:
                    </Col>
                    <Col sm={1}>
                        {this.formObject({data: this.state.form.refresh, options: refreshOptions, owner: this, controller: this.partial_controller})}
                    </Col>
                    <Col sm={1} className="grafana_control_label">
                        <HiOutlineRefresh className="grafana_refresh pointer" onClick={this.partial_controller?.refresh.bind(this)}/>
                    </Col>
                </Row>
            )
        }
	    return (<></>)
    }
    renderView() {
        this.state.filters = this.props.params.filters
        let grafana_panel = undefined
        if (grafana_panel !== undefined) {
//            this.hide_spinner()
        } else {
                grafana_panel = <iframe id="grafana_iframe" title="grafana_iframe" src={this.partial_controller.get_url()}
                        width="100%"
                        height="100%"
                        onLoad={this.hide_spinner.bind(this)}
                        className="grafana_iframe" />
                this.partial_controller.save(grafana_panel)
//		iframe.contentWindow.body.addEventListener('mouse_down', this.controller.mouse_down);
        }
        // Refer to the following for details on the spinner
	    // https://codepen.io/WebSonata/pen/bRaONB
        return (
            <>
                {this.get_control_bar()}
                <Row className="grafana_wrapper g-0" id={this.name}>
                    <Col>
                        <div className={"spinner_iframe "+this.get_spinner_state()}>
                            <div id="loader"></div>
                            <div id="loader_title">Cota Systems</div>
                        </div>
                        {grafana_panel}
                    </Col>
                </Row>
            </>
        )
    }
}
