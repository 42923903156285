// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import CompanyModelController from "./CompanyModelController"

export default class ContactModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
        let get_open_hours  = this.get_open_hours.bind(this)
        let get_close_hours = this.get_close_hours.bind(this)
        this.company = new CompanyModelController({params: {parent: this, index: this.index,
                            controller: this.controller, _nested: this._nested, _param: "company",
                            _binded: this.is_bound(false), is_view: true}})
        this.hours = {
            get open() {
                return get_open_hours()
            },
            get close() {
                return get_close_hours()
            }
        }
    }
    get id() {
        return this.data?.id
    }
    load({data, key}) {
        this._state = {}
        this._state.data = data
        this._state.key = key
    }
    get all() {
        return this.data
    }
    get name() {
        let selected = this.controller.getState({key: "search", param: "company.selected_value"})
        if ((selected?.name !== undefined && selected?.name !== null) && Object.keys(selected).length > 0) {
            return selected.name
        }
        let search = this.controller.getState({key: "search", param: "company.search_value"})
        if(typeof search === "string") {
            return search
        }
        if (this.data?.name !== undefined && this.data?.name !== "") {
            return this.data.name
        }
        return ""
    }
    get people() {
        let people = []
        if (Array.isArray(this.data?.people)) {
            people = [...people, ...this.data?.people]
        }
        if (Array.isArray(this.company?.employees)) {
            people = [...people, ...this.company?.employees]
        }
        return people
    }
    get_person(index) {
        return this.controller.persons.get({id: index})
    }
    get address() {
        // Must return Address in order for the rest of locations to work
        return (this.data?.address?.address !== undefined && this.data?.address?.address !== null) ? this.data?.address?.address : this.company?.address
    }
    set address(value) {
        if (this.company?._id === undefined) {
            this.company.address = value
        } else {
            this.setState({param: "address.address", value: value})
        }
    }
    get address_2() {
        if (this.company?._id !== undefined && this.data?.address?.notnull("address_2")) {
                return this.data?.address?.address_2
        }
        return this.company?.address_2
    }
    set address_2(value) {
        if (this.company?._id === undefined) {
            this.company.address_2 = value
        } else {
            this.setState({param: "address.address_2", value: value})
        }
    }
    get country() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("country")) {
                return this.data.address.location.city
        }
        return this.company?.country
    }
    set country(value) {
        if (this.company?._id === undefined) {
            this.company.country = value
        } else {
            this.setState({param: "address.location.country", value: value})
        }
    }
    get city() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("city")) {
                return this.data.address.location.city
        }
        return this.company?.city
    }
    set city(value) {
        if (this.company?._id === undefined) {
            this.company.city = value
        } else {
            this.setState({param: "address.location.city", value: value})
        }
    }
//    get state() {
//        return (this.data?.address?.location?.notnull("state")) ? this.data.address.location.state: this.company?.state
//    }
    get state() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("state")) {
                return this.data.address.location.state
        }
        return this.company?.state
    }
    set state(value) {
        if (this.company?._id === undefined) {
            this.company.state = value
        } else {
            this.setState({param: "address.location.state", value: value})
        }
    }
    get zip() {
        return (this.data?.address?.location?.notnull("zip")) ? String(this.data.address.location.zip): this.company?.zip
    }
    set zip(value) {
        if (this.company?._id === undefined) {
            this.company.zip = value
        } else {
            this.setState({param: "address.location.zip", value: value})
        }
    }
    get fax() {
        if (this.company?._id !== undefined && this.data?.notnull("fax")) {
                return this.data?.fax
        }
        return this.company?.fax
    }
//    get fax() {
//        return (this.data?.fax !== undefined && this.data?.fax !== null) ? this.data?.fax : this.company?.fax
//    }
    set fax(value) {
        if (this.company?._id === undefined) {
            this.company.fax = value
        } else {
            this.setState({param: "fax", value: value})
        }
    }
    get email() {
        if (this.company?._id !== undefined && this.data?.notnull("email")) {
                return this.data?.email
        }
        return this.company?.email
    }
//    get email() {
//        return (this.data?.email !== undefined && this.data?.email !== null) ? this.data?.email : this.company?.email
//    }
    set email(value) {
        if (this.company?._id === undefined) {
            this.company.email = value
        } else {
            this.setState({param: "email", value: value})
        }
    }
    get phone() {
        if (this.company?._id !== undefined && this.data?.notnull("phone")) {
                return this.data?.phone
        }
        return this.company?.phone
    }
//    get phone() {
//        return (this.data?.phone !== undefined && this.data?.phone !== null) ? this.data?.phone : this.company?.phone
//    }
    set phone(value) {
        if (this.company?._id === undefined) {
            this.company.phone = value
        } else {
            this.setState({param: "phone", value: value})
        }
    }
    get roles() {
        if (this.data?.roles === undefined) {
            return {
                payor: false,
                shipper: false,
                consignee: false
            }
        }
        return this.data?.roles 
    }
    get payor() {
        return (this.roles?.payor === undefined) ? false : this.roles?.payor
    }
    set payor(value) {
        this.controller.setState({key: "data", param: "contact.roles.payor", value: value})
    }
    get shipper() {
        return (this.roles?.shipper === undefined) ? false : this.roles?.shipper
    }
    set shipper(value) {
        this.controller.setState({key: "data", param: "contact.roles.shipper", value: value})
    }
    get consignee() {
        return (this.roles?.consignee === undefined) ? false : this.roles?.consignee
    }
    set consignee(value) {
        this.controller.setState({key: "data", param: "contact.roles.consignee", value: value})
    }
    get importer() {
        return this.roles?.importer
    }
    set importer(value) {
        this.controller.setState({key: "data", param: "contact.roles.importer", value: value})
    }
    get location() {
        if (this.data?.address?.location?.city === "" && this.data?.address?.location?.zip === "") {
            return this.company?.location
        }
        return this.zip+" "+this.city+", "+this.state+" "+this.country
    }
    get raw_location() {
        if (this.data?.address?.location === undefined) {
            return this.company?.raw_location
        }
        return this.data?.address?.location
    }
    get same_billing_address() {
        return this.data?.same_billing_address
    }
    set same_billing_address(value) {
        this.controller.setState({key: "data", param: "contact.same_billing_address", value: value})
    }
    get require_appointments() {
        return this.data?.require_appointments
    }
    set require_appointments(value) {
        this.controller.setState({key: "data", param: "contact.require_appointments", value: value})
    }
    get liftgate() {
        return this.data?.liftgate
    }
    set liftgate(value) {
        this.controller.setState({key: "data", param: "contact.liftgate", value: value})
    }
    get_open_hours() {
        return this.hours_open
    }
    get hours_open() {
        return this.data?.hours_open
    }
    set hours_open(value) {
        this.controller.setState({key: "data", param: "contact.hours_open", value: value})
    }
    get_close_hours() {
        return this.hours_close
    }
    get hours_close() {
        return this.data?.hours_close
    }
    set hours_close(value) {
        this.controller.setState({key: "data", param: "contact.hours_close", value: value})
    }
    get street_address() {
        if(this.address_2 === null || this.address_2 === undefined) {
            return this.address
        }
        return this.address +" "+this.address_2
    }
    get full_location() {
        return this.zip+", "+this.city+", "+this.state+" "+this.country
    }
    get full_address() {
        return this.street_address+", "+this.location
    }
    get documents() {
        return this.data.documents
    }
    set documents(value) {
        this.data.documents = value
    }
    number_reduction(value) {
        if (value !== undefined && value !== null) {
            return value.replace(/[^0-9]/g, '');
        }
        return value
    }
    get_area_code(phone_number) {
        return Number((this.number_reduction(phone_number)?.slice(0,3)))
    }
    get_number(phone_number) {
        return (this.number_reduction(phone_number))?.slice(3,phone_number.length)
    }
    build_phone_number(phone_number) {
        if (this.get_number(phone_number) !== undefined) {
            return {
                country_code: 1,
                area_code: this.get_area_code(phone_number),
                phone_number: this.get_number(phone_number),
                extension: 0
            }
        }
        return undefined
    }
    build_role(role) {
        return {code: role}
    }
    generate_roles() {
        let roles = []
        if (this.payor) { roles.push(this.build_role(6)) }
        if (this.shipper) { roles.push(this.build_role(8)) }
        if (this.consignee) { roles.push(this.build_role(7)) }
        if (this.importer) { roles.push(this.build_role(9)) }
        return roles
    }
    resolve_address() {
        let address = this.controller.getState({key: "search", param: "address.selected_value"})
        if (address?.address !== undefined) {
            return address?.address
        }
        address = this.controller.getState({key: "search", param: "address.search_value"})
        return address
    }
    resolve_zip() {
        let _zip = this.controller.getState({key: "search", param: "zip.selected_value"})
        if (_zip !== undefined) {
            return _zip.zip
        }
        _zip = this.controller.getState({key: "search", param: "zip.search_value"})
        return _zip
    }
    get summary() {
        let _summary = {
            company: {},
	        contact: JSON.parse(JSON.stringify(this.data))
        }
	    delete _summary.contact.company
//        if (this._id !== undefined) {
        _summary.contact.id = this._id 
        _summary.contact.email  = {email: this.email}
        _summary.contact.phone  = this.build_phone_number(this.phone)
        _summary.contact.fax    = this.build_phone_number(this.fax)
        if (this.address !== undefined && this.address !== null) {
            _summary.contact.address = {
                address: this.resolve_address(),
                address_2: this.address_2,
                location: { 
                    city: this.city,
                    state: this.state,
                    zip: this.resolve_zip(),
                    country: this.country
                }
            }
//            }
            
        }
        if (this.same_billing_address !== undefined) {
            _summary.contact.same_billing_address = this.same_billing_address
        }
        if (this.liftgate !== undefined) {
            _summary.contact.liftgate = this.liftgate
        }
        if (this.require_appointments !== undefined) {
            _summary.contact.require_appointments = this.require_appointments
        }
        _summary.contact.name   = this.name
        _summary.contact.roles  = this.generate_roles()
        _summary.contact.hours_open = this.hours_open
        _summary.contact.hours_close = this.hours_close
        let selected = this.controller.getSearch({key: "company", selected: true, param: "id"})
        if (selected !== undefined && selected !== null) { 
		console.log(selected)
            _summary.company = {id: selected}
        } else {
            _summary.company = this.company.summary
        }
        if (this.id !== undefined) {
            _summary.contact.id     = this.id
            delete _summary.company
        }
        delete _summary.contact.documents
        console.log(this)
        console.log(_summary)
        _summary.contact.scrub()
        return _summary
    }
}
