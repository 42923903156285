import _viewController from "controllers/ViewController/_viewController.jsx"

export default class CompanyCardController extends _viewController {
    constructor (props) {
        super(props)
    }
    get type() {
        return (this.view.state.role === "shipper") ? "pickup" : this.view.state.role 
    }
    get_status_css() {
        if (this.view?.state?.data?.status !== undefined) {
            switch(this.view?.state?.data?.status) {
                case "shipper":
                    return "shipper"
                case "payor":
                    return "payor"
                case "consignee":
                    return "consignee"
                default:
                    return "unknown"
            }
        }
        return "unknown"
    }
    delete_stop() {
        let objs = this.controller.getState({key: "data", param: this.state?.type})
        objs.splice(this.props?.params?.index, 1)
        this.controller.setState({key: "data", param: this.state?.type, value: objs})
        this.controller.update()
    }
}