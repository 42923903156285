// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';

// MODULE SPECIFC IMPORTS
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./ManageUsers.controller.jsx"
import "./ManageUsers.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import grafana_config from "assets/grafana_config.json"

export default class ManageUsersPage extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this.state.panel_name       = "employees"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config["pages"]["employees"]
    }
    renderGrafanaPanel() {
        if(this.state?.company_id === undefined) {return <></>}
        return (
            <GrafanaFrame params={{ parent: this, panel_configs: this.state.panel_configs, 
                                    panel_name: this.state.panel_name,
                                    filters: {
                                        company_id: this.state?.company_id
                                    }
                                }}
            />
        )
    }
    renderMain() {
        console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Manage Users"}}/>
                        <Row className="generic_100 cota_panel_object g-0 add_shadow">
                            <Col className="generic_100">
                                <Row className="header_row">
                                    <Col className="header_title">
                                        Current Users
                                    </Col>
                                    <Col sm={8}/>
                                    <Col>
                                        {/* <Button>
                                             New User
                                         </Button> */}
                                    </Col>
                                </Row>
                                <Row className="grafana">
                                    <Col>
                                        {this.renderGrafanaPanel()}
                                    </Col>
                                </Row>
                            </Col>                                               
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}