// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import CompanyModelController from "./CompanyModelController";

export default class UserModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, controller: this.controller}})
        let get_active_role = this.get_active_role.bind(this)
        let get_first_name  = this.get_first_name.bind(this)
        let get_last_name   = this.get_last_name.bind(this)
        this.role = {
            get active() {
                return get_active_role()
            },
            get a() {
                return get_active_role()
            },
            get first() {
                return get_active_role((get_active_role())?.[0])
            }
        }
        this.name = {
            get full() {
                return get_first_name() + " " + get_last_name()
            },
            get first() {
                return get_first_name()
            },
            get last() {
                return get_last_name()
            }
        }
    }
    get_company_ids(index) {
        if (index === undefined) {
            return this.data.company_ids
        }
        return this.companies?.[index]
    }
    get_role_ids(index) {
        if (index === undefined) {
            return this.data.role_ids
        }
        return this.roles?.[index]
    }
    get_active_role() {
        if (this.data?.roles !== undefined) {
            return this.data?.roles[Object.keys(this.data?.roles).filter((key) => this.data?.roles[key].status === "ACTIVE")]
        }
        return undefined
    }
    get_first_name() {
        return this.data?.given_name
    }
    get family_name() {
        return this.data?.family_name
    }
    set family_name(value) {
        this.data.family_name = value
    } 
    get_last_name() {
        return this.data?.family_name
    }
    get given_name() {
        return this.data?.given_name
    }
    set given_name(value) {
        this.data.given_name = value
    }
    get preferences() {
        return this.data?.preferences
    }
    get phone_number() {
        return this.data?.phone_number
    }
    set phone_number(value) {
        this.data.phone_number = value
    }
    get email() {
        return this.data?.email
    }
    set email(value) {
        this.data.email = value
    }
    get roles() {
        return this.data?.roles
    }
    get companies() {
        return this.data?.companies
    }
    get socials() {
        return this.data?.socials
    }
    get facebook() {
        return this.socials?.facebook
    }
    set facebook(value) {
        this.data.socials.facebook = value
    }
    get instagram() {
        return this.socials?.instagram
    }
    set instagram(value) {
        this.data.socials.instagram = value
    }
    get linkedin() {
        return this.socials?.linkedin
    }
    set linkedin(value) {
        this.data.socials.linkedin = value
    }
    get twitter() {
        return this.socials?.twitter
    }
    set twitter(value) {
        this.data.socials.twitter = value
    }
    get settings() {
        return this.data?.settings
    }
    get measurement_system() {
        return this.settings?.measurement_system
    }
    set measurement_system(value) {
        return this.data.settings.measurement_system = value
    }
    // get data() {
    //     return this.controller.getState({key: "context"})?.get_self()
    // }
    get data() {
        return (this._state?.data !== undefined) ? this._state?.data : this.controller.getState({key: this._data_key, param: "user"})
    }
    get summary() {
        let _summary = {}
        _summary.id = this._id
        _summary.family_name = this.family_name
        _summary.given_name = this.given_name
        _summary.name = this.name.full
        _summary.email = {email: this.email}
        _summary.settings = this.settings
        _summary.settings.language = this.settings?.language?.code
        _summary.socials = this.socials
        _summary.scrub()
        return _summary
    }
}
