// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./Help.partial.controller.jsx"
import "./Help.partial.css"
import Modal from "../HelpModal/Help.modal.jsx"

export default class HelpPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_contoller = new Controller({params: {view: this, controller: this?.controller}})
        this._modal = Modal
        // this.state._toggled_modal = true
    }
    // get context() {
    //     return this.controller.context
    // }
    get page() {
        return this.parent?.state?.context?.page
    }
    renderView() {
        // NEED to get the view of the current menu selected or path menu
        // NEED TO FIND A WAY TO TOGGLE MODALS FROM THE SIDEBAR
        console.log(this)
        return (
            <BsQuestionCircle id={this.name} onClick={() => this.toggle_modal({toggle_state: true})} className="menuListIcon"/>
            // <Row id={this.name}>
            //     <Col>

            //     </Col>
            // </Row>
        )
    }
}