// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap"
import { FaDollarSign } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Load_Details.partial.controller"
// import Modal from "module/pages/Loads/ViewLoad/EditLoadModal/EditLoad.modal.jsx"
import _baseViewComponent from "components/General/_baseViewComponent.jsx"


import "./Load_Details.css"

export default class LoadDetails extends _basePartial {
    constructor(props) {
        super(props)
        // this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
    }
    render_commodities() {
        // making return just one commodity leaving functionaly for more later.
        return (<>

            <Row>
                <Col>
                    carrier
                </Col>
                <Col>
                    {this.controller?.load?.carrier?.name}
                </Col>
                <Col>
                    Commodities
                </Col>
                <Col>
                    {this.controller?.commodities?.[0]?.name ?? "no description"}
                </Col>
            </Row>

            {/* {this.controller?.commodities?.map((commodity, index) => {
                return (
                    <Row key={"commodity_"+index}>
                        <Col>
                            {commodity?.name}
                        </Col>
                    </Row>
                )
            })} */}
        </>)
    }
    // render_special_requirements() {
    //     return (<>
    //         <Row>
    //             <Col>
    //                 Special Needs
    //             </Col>
    //         </Row>
    //         {this.controller.special_requirements?.map((requirement, index) => {
    //             return (
    //                 <Row key={"requirement_"+index}>
    //                     <Col>
    //                         {requirement?.name}
    //                     </Col>
    //                 </Row>
    //             )
    //         })}
    //     </>)
    // }

    render_trip_logs(logs) {
        // if(logs.length === 0) { return this.render_no_logs() }

        return (
            logs?.map((log, index) => (this.render_trip_log(log)))


        )
    }

    render_trip_log(log) {
        return (
            <Row className="g-0">
                <Col>
                    {log?.inference ?? log?.details?.title}
                </Col>
                <Col>
                    {log?.date ?? "no date"}
                </Col>
                <Col>
                    {log?.address?.location?.city},{log?.address?.location?.state}
                </Col>

            </Row>
        )
    }

    render_no_logs() {
        return (
            <Row className="g-0">
                <Col className="center">
                    No logs have been created yet
                </Col>
            </Row>
        )
    }
    render_dimensions() {
        return (<>
            <Col>
                <Row>
                    <Col>
                        Driver
                    </Col>
                    {this.controller?.load?.trips ?
                        <Col>
                            {this.controller?.load?.trips[0]?.driver.name ?? `${this.controller?.load?.trips[0]?.truck?.driver?.first_name ?? "no name given"}  ${this.controller?.load?.trips[0]?.truck?.driver?.last_name ?? ""}`}
                        </Col>
                        : <Col></Col>
                    }
                    <Col>
                        Truck Number
                    </Col>
                    {
                        this.controller?.load?.trips ?
                            <Col>
                                {this?.controller?.load?.trips[0]?.truck?.asset_number}
                            </Col>
                            : <Col></Col>
                    }
                </Row>
                <Row>
                    <Col>
                        MC#
                    </Col>
                    <Col>
                        {this.controller?.load?.carrier?.company?.motor_carrier ?? "NO MC#"}
                    </Col>
                    <Col >
                        DOT#
                    </Col>
                    <Col>
                        {this.controller?.load?.carrier?.company?.dot_number ?? "NO DOT#"}
                    </Col>
                </Row>
            </Col>
        </>)
    }
    renderView() {
        return (
            <Col className="card_window cota_panel_border" id={this.name}>
                <Row className="g-0">
                    <Col className="g-0">
                        <Row className="g-0">
                            <Col className="g-0">
                                &nbsp;
                            </Col>
                            <Col sm={2} className={"g-0 payor"}>
                                CARRIER
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="row_pad">
                            {this.render_dimensions()}
                        </Row>
                        <Row className="row_pad">
                            <Col className="std_font">
                                <Row>
                                    {this.render_commodities()}
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Trip Logs
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.controller?.load?.trips?.map((logs, index) => (this.render_trip_logs(logs.logs)))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }
}
