// GENERAL REACT MODULES

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject"
import asset_request from "assets/graphql/Assets/Assets.graphql.json"

export default class Notes extends _baseApiObject {
    constructor(props) {
        super(props)
        this.requests   = {
            general: ""
        }
    }
}
