// GENERAL REACT MODULES
import _baseComponent from "./_baseComponent";

export default class _baseApiObject extends _baseComponent {
    constructor(props) {
        super(props)
	    this.state.cache = []
        this.state.data = {}
        this.state.keys = {}
        this.test_data  = undefined
    }
    load_from_test({id, params}) {
        let data = this.test_data[id]
        return data
    }
    load_from_api({id, params}) {
        let data = this.schema
        if (this.test_data !== undefined) {
            if (this.state.parent.mode()) {
                data = this.test_data[id]
            }
        }
        return data
    }
    load_from_cache({id, params}) {
        let data = this.state.data[id]
        if (data === undefined) {
            data = this.load_from_api({id, params})
            if (data !== undefined) {
                data.expiry = Math.floor((new Date()).getTime() / 1000)
                this.state.data[id] = data
            }
        }
        if (data !== undefined) {
            data = this.load_sub_objects(data)
            data.uuid = id
        }
        return data
    }
    load_sub_objects(data) { 
        return data
    }
    async create({caller, id, params, callback, json}) {
        let results = await this.parent.post({caller: caller, callback: callback, params: params, body: params?.body})
        return results?.data?.[Object.keys(results?.data)?.[0]]?.id
    }
    async get({id, params}) {
        let data = this.schema
        // data = this.load_from_cache({id: id, params: params})
        data = await this.parent.post({body: params?.body})
        return data
    }
    decompose_params(params) {
        var regExp = /\(([^)]+)\)/;
        if (params !== undefined) {
            let matched = params.match(regExp)
            if (matched !== undefined && matched !== null) {
                let decomposed = (params.match(regExp)[1]).split(" ")
                decomposed.map((o, i) => {
                    decomposed[i] = o.replaceAll(':', '').replaceAll('%', '').replaceAll('"', '')
                })
                return decomposed
            }
        }
        return undefined
    }
    async ask({caller, id, params, callback, nocache}) {
        if (callback === undefined) {
            callback = {}
        }
        if (callback?.p?.name === undefined) {
            callback.p = {
                name: this.name.toLowerCase()
            }
        }
        if (caller?._isMounted) {
            let response = this.schema
            if (!nocache || nocache === undefined) {
                this.state.cache = this.state.cache.filter((cache) => (cache.ts-Date.now()) > -300000 )
                let decomposed = this.decompose_params(params?.body?.query)
                let exists = undefined
                if (decomposed !== undefined) {
//                  exists = this.state.cache.filter((cache) => (cache?.[decomposed[0]])?.toString().toLowerCase().startsWith(decomposed[1]?.toString().toLowerCase()))
                }
                if (exists?.length > 0) {
//                  let results = {
//                      data: {}
//                  }
//                  results.data[params?.data?.endpoint] = exists
//                  return results
                }
            }
            response = await this.parent.post({caller: caller, callback: callback, params: params, body: params?.body})
            if (nocache || nocache === undefined) {
                if (response?.data !== undefined) {
                    console.log("--- CACHE LOADER ---")
                    console.log(response?.data)
                    let key = Object.keys(response.data)?.[0]
                    let data = response.data?.[key]
                    console.log(data)
                    Object.keys(response.data).map((k, i) => {
                        if (Array.isArray(response.data[k])) {
                            response.data[k].map((obj, index) => {
                                let exists = this.state.cache.filter((cache) => cache.id === obj.id)
                                if (exists.length === 0) {
                                    obj.ts = Date.now()
                                    this.state.cache.push(obj)
                                }
                            })
                        }
                    })
                }
	        }
            return response
        }
        return undefined
    }
    update({id, params, values, json}) {
        let data = this.get({id: id})
        if (json !== undefined) {
            data = json
        } else {
            if (values !== undefined) {
                Object.keys(values).map((key, value) => {
                    if (Array.isArray(data[key])) {
                        data[key].push(values[key])
                    } else {
                        data[key] = values[key]
                    }
                    return undefined
                })
            }
        }
        this.state.data[id] = data
        return this.get({id: id})
    }
    delete({id, params}) {

    }
    get_schema() {
        return this.schema
    }
    append_values(values) {
        let append_string = " "
        if (values !== undefined) {
            Object.keys(values).map((k, i) => {
                append_string = append_string+', '+k+':'+((!isNaN(values[k])) ? values[k]: '"'+values[k]+'"')
            })
        }
        return append_string
    }
}
