// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import load_request from "assets/graphql/Loads/Loads.graphql.json"
import payor_request from "assets/graphql/Loads/loads.payor.graphql.json"
import load_search   from "assets/graphql/Loads/loads.search.graphql.json"

export default class Loads extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: load_request,
            customers: payor_request,
            creators: payor_request
        }
    }
    async search({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            // TODO: Make sure the loads searched are filtered by user's company's loads as well.
            let body = JSON.parse(JSON.stringify(load_search))
            body.query = body.query.replace("loads", 'loads(name: "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        if (callback !== undefined) {
            callback({caller: caller, params: params, results: results})
        }
        return results
    }
}
