// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"
import graph_request from "./Contacts.search.graphql.json"
import name_reqeust from "assets/graphql/Contacts/Contacts.names.graphql.json"
import id_reqeust from "assets/graphql/Contacts/Contacts.search.graphql.json"
import delete_request from "assets/graphql/Contacts/Contacts.delete.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Contacts extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
    }
    async search({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(graph_request))
            body.query = body.query.replace("contacts", 'contacts(name: "'+value+'%")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        if (callback !== undefined) {
            callback({caller: caller, params: params, results: results})
        }
        return results
    }
    async delete({caller, value, callback, params}) {
        let results = value
        let body = JSON.parse(JSON.stringify(delete_request))
        body.query = body.query.replace("deleteContact", 'deleteContact(id: "'+value+'")')
        params = (params === undefined) ? {} : params
        params.body = body
        results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
    }
    async gid({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(id_reqeust))
            body.query = body.query.replace("contacts", 'contact(id: "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
//        if (callback !== undefined) {
//            callback.f({caller: caller, params: params, results: results})
//        }
        return results
    }
}
