// GENERAL REACT MODULES
// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import document_types from "assets/graphql/Document_types/document_types.graphql.json"
import self_request from "assets/graphql/Users/self.graphql.json"

export default class UploadPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.isMounted  = true
        this.requests   = [{request: document_types, 
                                    callback: {p: {name: "document_types", var: "document_types", disp: "label"}}, 
                                    endpoint: "document_types"},
                            {request: self_request,
                                    callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    load_user({caller, params, results}) {
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data.self)
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.forceUpdate()
        }
    }
    upload_user(event) {
        this.partial_controller.browse_user(event)
        this.partial_controller.upload()
    }
    upload_contact(event){
        let value = URL.createObjectURL(event.target.files[0])
        this.state.url = value
        this.controller.view.forceUpdate()
        this.state.queue.push(event.target.files[0])
    }
    browse_user(event){
        this.controller.setState({key: "current_file", value: event.target.files[0]})
        this.controller.view.forceUpdate()
    }
    browse(event) {
        this.controller.setState({key: "current_file", value: event.target.files[0]})
        this.state.url = this.controller.getState({key: "current_file"})
        this.controller.view.forceUpdate()
    }
    async process_queue(id) {
        console.log("PROCESS QUEUE",this)
        if(this.view.state.endpoint === "contact" || "carrier"){
            let file = this.controller.getState({key: "current_file"})
            let body = {
                file: file,
                private: false,
                endpoint: this.view.state.endpoint,
                id: id
            }
            this.upload(body)
        }
        if (id !== undefined) {
            for (const queued of this.view.state.queue) {
        //    this.view.state.queue.map((queued, index) => {
                queued.id = id
                await this.upload(queued)
            }
        }
        return {}
    }
    async upload(queued) {
        console.log("--------- UPLOADING ---------")
        console.log(this)
        let endpoint = this.getState({key: "endpoint"})
        let file = this.controller.getState({key: "current_file"})
        if(file === undefined) { return }
        let body = {}
        if (queued === undefined) {
            body = {
                file: file,
                doc_type: this.getState({key: "document", param: "type"}),
                private: this.controller.view.state?.private_file,
                endpoint: endpoint,
                id: this.controller?.[endpoint]?._id
            }
        } else {
            body = queued
        }
        if (body?.id !== undefined && body?.id !== null) {
            await this.controller.api.upload.file_upload({caller: this.controller,
                                                    body: body,
                                                    callback: {
                                                        f: this.upload_callback,
                                                        p: {endpoint: body.endpoint}}})
        } else {
	        console.log(body)
            if (queued === undefined) {
                this.view.state.queue.push(body)
            }
        }
        this.view.toggle_modal({toggle_state: false})
        this.handle_reset()
        this.controller.view.forceUpdate()
        return {}
    }
    async file_download(){
        let endpoint = this.getState({key: "endpoint"})
        let file = this.controller.getState({key: "current_file"})
        let body = {
            file: file,
            doc_type: this.getState({key: "document", param: "type"}),
            private: false,
            endpoint: endpoint,
            id: this.controller?.[endpoint]?._id
        }
        this.controller.api.upload.file_download({caller: this.controller,
            body: body,
            callback: {
                f: this.upload_callback,
                p: {endpoint: body.endpoint}}})
    }
    upload_callback({results, caller, params}) {
	    console.log("FINISHED UPLOAD")
    }
    update_file(event, file, filenum) {
        let endpoint = this.getState({key: "endpoint"})
        let id = this.controller?.[endpoint]?._id
            if (id !== undefined) {
            let body = {
                file: event.target.files[0],
                docType: this.getState({key: "document", param: "type"}),
                private: this.controller.view.state?.private_file, 
                endpoint: endpoint,
                id: this.controller?.[endpoint]?._id
            }
            file.endpoint = body.endpoint
            file.resource_id = file.id
            file.id = body.id
            this.remove_file({filenum: filenum})
            this.controller.api.upload.file_update({caller: this.controller, 
                                                    value: file, 
                                                    body: body,
                                                    endpoint: body.endpoint, 
                                                    callback: {
                                                        f: this.update_callback, 
                                                        p: {endpoint: body.endpoint}}})
            this.handle_reset()
            this.controller.view.forceUpdate()
        }
    }
    update_callback({results, caller, params}) {
        let id = caller.endpoint_id
        caller.api.upload.fetch_files({caller: caller, endpoint: params.endpoint, id: id})
        caller.view.forceUpdate()
    }
    remove_file({filenum: index}) {
        let files = this.controller.getState({key: "files"})
        files.splice(index, 1)
        this.controller.setState({key: "files", value: files})
        this.controller.view.forceUpdate()
    }
    handle_doc_delete(file, filenum) {
        this.remove_file({filenum: filenum})
        file.endpoint = this.controller.view.state.file_endpoint
        file.resource_id = file.id
        file.id = this.controller.endpoint_id
        // console.log(file, filenum)
        this.controller.api.upload.file_delete({caller: this, value: file})
        this.controller.view.forceUpdate()
    }
    handle_checkbox(event) {
        let bool = event.target?.checked
        this.controller.setState({key: "private_file", value: bool})
        // console.log(this.controller.view.state.private_file)
        this.controller.view.forceUpdate()
    }
    handle_reset() {
        this.controller.setState({key: "private_file", value: true})
        this.controller.setState({key: "current_file", value: {name: undefined}})
    }
    handle_close() {
        this.handle_reset()
        this.view.toggle_modal({toggle_state: false})
        this.controller.view.forceUpdate()
    }
    resolve_file_type(value) {
        let file_type = this.view.state.document_types.find((type) => { return type.value === value });
        return file_type?.label
    }
    remove_file(index) {
        this.state.queue.splice(index, 1)
        this.forceUpdate()
    }
}
