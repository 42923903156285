import { default as dayjs } from 'dayjs';
export default class _baseController {
    constructor(props) {
        this.view = undefined
        this._state = {
            data: undefined
        }
    }
    processEventReaction(decoded) { }
    process_date(selection) {
        switch(selection) {
            case "today":
                return { from: (dayjs().startOf('day').unix() * 1000), to: (dayjs().endOf('day').unix() * 1000)}
            case "yesterday":
                return { from: (dayjs().subtract(1, 'day').unix() * 1000), to: (dayjs().subtract(1, 'day').endOf('day').unix() * 1000)}
            case "this_week":
                return { from: (dayjs().startOf('week').unix() * 1000), to: (dayjs().endOf('day').unix() * 1000)}
            case "last_week":
                return { from: (dayjs().subtract(7, 'day').startOf('week').unix() * 1000), to: (dayjs().subtract(7, 'day').endOf('week').unix() * 1000)}
            default:
                return undefined
        }
    }
    _notnull(v) {
        if (v !== undefined && v !== null) {
            return true
        }
        return false
    }
}
Object.byString = function({obj: o, obj_string:s, obj_value:v, index: index}) {
    if (o !== undefined) {
        if (s !== undefined) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (o !== null && o !== undefined) {
                if (o?.[k] === undefined) {
                    o[k] = {}
                }
                if (v !== undefined) {
                    if (a.length-1 === i) {
                        if (index === undefined) {
                            o[k] = v
                        } else {
                            if (Array.isArray(o[k])) {
                                o[k][index] = v
                            } else {
                                o[k] = v
                            }
                        }
                    }
                }
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
		}
            }
        }
        return o;
    }
    return undefined
}
Object.get_by_string = function({obj: o, obj_string:s}) {
    if (o !== undefined) {
        if (s !== undefined) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (o[k] === undefined) {
                    // o[k] = {}
                    return undefined
                }
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
        }
        return o;
    }
    return undefined
}
String.prototype.Capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.notempty = function() {
    if (this.notnull() && this !== "") {
        return true
    }
    return false
}
//String.prototype.notnull = function() {
//    if (this !== undefined && this !== null) {
//        return true
//    }
//    return false
//}
Object.prototype.notempty = function() {
    if (this.notnull() && Object.keys(this).length !== 0) {
        return true
    }
    return false
}
Object.prototype.notnull = function(k) {
    let o = (k === undefined) ? this : this?.[k]
    if (o !== undefined && o !== null) {
        return true
    }
    return false
}
Object.prototype.isnull = function() {
    if (this !== undefined || this !== null) {
        return false
    }
    return true
}
Object.prototype.scrub = function() {
    Object.keys(this).map((k, i) => {
        if (this?.[k] === undefined || this?.[k] === null) {
            delete this[k]
            return
        }
        if (typeof this?.[k] === "object") {
            if (this?.[k] !== undefined) {
                if (k !== null & k !== undefined) {
                    this[k].scrub()
                } else {
                    delete this[k]
                }
            }
            if (k !== null & null !== undefined) {
                if (Object.keys(this[k]).length === 0) {
                    delete this[k]
                }
            }
        }
        // if (typeof this[k] === "string") {
        //     if (!this?.[k].notempty()) {
        //         delete this[k]
        //     }
        // }
    })
    return this
}
